import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import { CheckboxLabel, ToolTipWrapper } from '../../styles/ezio-styles/Controls-styles';
import * as colors from "../../styles/ezio-styles/ColorScheme";
import { ToolTipText } from '../../styles/ezio-styles/LandingPage-styles';

type LocationToggleProps = {
    loadAllLocations: boolean,
    setSubmitButtonDisabled: Function,
    setTempSelectedChargeLocation: Function,
    setSelectedChargeLocation: Function,
    setLoadAllLocations: Function
}


export default function LocationToggle({loadAllLocations, setSubmitButtonDisabled, setTempSelectedChargeLocation, setSelectedChargeLocation, setLoadAllLocations}: LocationToggleProps) {
    const [cursorCoords, setCursorCoords] = useState({ x: 0, y: 0 });
    const popupCopy = (loadAllLocations ? "Currently showing all locations" : "Currently showing primary locations");
    const [showPrimaryParkingToolTip, setShowPrimaryParkingToolTip] = useState<Boolean>(false);

    const switchStyle = {
            '& .MuiSwitch-track': {
                backgroundColor: colors.ezio_medium_grey, // Track color
                },
                '& .MuiSwitch-thumb': {
                backgroundColor: colors.ezio_dark_green, // Thumb color
                },
                '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#fff', // Checked color
                    '& + .MuiSwitch-track': {
                      backgroundColor: "rgba(3, 94, 91, 0.7)", // Checked track color
                    },
                }
    };

    const boxStyle = {
        height: '36px',
        border: `2px solid ${colors.ezio_light_grey}`,
        borderRadius: '5px',
        paddingLeft: '10px'
    };

    const handleShowPrimaryParkingToolTip = (show: Boolean, mouseEvent: any) => {
        setCursorCoords({x:mouseEvent.pageX, y:mouseEvent.pageY});
        setShowPrimaryParkingToolTip(show);
    }
    return (
    <Box
    onMouseEnter={(e) => {handleShowPrimaryParkingToolTip(true, e)}}
    onMouseLeave={(e) => {handleShowPrimaryParkingToolTip(false, e)}}
      sx={boxStyle}
    >
            {showPrimaryParkingToolTip &&
                <ToolTipWrapper cursorCoords={cursorCoords}>
                    <ToolTipText>
                        Toggles viewing all locations or just primary parking locations. {popupCopy}.
                    </ToolTipText>
                </ToolTipWrapper>
            }
        <CheckboxLabel>Show All Locations</CheckboxLabel> 
        <Switch 
            id="location-toggle"
            sx={switchStyle}
            checked={loadAllLocations} onChange={() => {
                // Set to "All Locations" upon switch
                // We have a variety of state issues that occur if we don't ...
                setTempSelectedChargeLocation("All Locations");
                setSelectedChargeLocation("All Locations");
                setLoadAllLocations(loadAllLocations ? false : true)
            }}
        /> 
    </Box>
  );
}