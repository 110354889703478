import styled, { createGlobalStyle } from "styled-components/macro"
import * as colors from "./ColorScheme";

export const HeaderRule = styled.hr`
    width: 100%;
    height: 0px;
    margin-top: 25px;
    margin-bottom: 25px;
    border: 1px solid ${colors.ezio_light_grey};;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    display: ${(props) => props.hidden ? 'none' : ''};
`

export const ProductWrapper =  styled.div`
    font-family: "Arimo", "Open Sans", "HelveticaNeue", "Helvetica Neue", "Arial", "sans-serif";
    box-sizing: border-box;
    width: 90%;
    margin-left: 110px;
    margin-right: 20px;
    margin-bottom: 50px;
`;

export const LoadingContainer = styled.div`
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    width: 15vw;
    height: 15vh;
`

export const LoadingCaption = styled.div`
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const ControlBoxPlaceholder = styled.div`
    height: 40px;
`

export const InfoDisplay = styled.main`
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin: 0 50px 100px;
`

export const ControlsPlaceholder = styled.div`
    height: ${(props) => props.hidden ? '0px' : '40px'};
    width: 100%;
`;

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family:  "Arimo", "Roboto", Arimo, Roboto;
        color: ${colors.ezio_dark_grey};
        font-style: normal;
        font-weight: normal;
        src: url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,700;1,400&display=swap');
        src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
    }
`