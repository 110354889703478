import { tableDateFormat } from "./idling-csv-helper-functions"

export function vehicleIdDisplay (vcl) {
  if(vcl) {
  if (vcl.asset_id) return vcl.asset_id
  if (vcl.user_defined_vin && vcl.user_defined_vin !== 'null') return vcl.user_defined_vin
  if (vcl.vin) return vcl.vin
  }
}

export const formatCellData = (cell) => {
  // replace w/ vin where no asset_id
  if (cell.column.id === 'asset_id'){
    return vehicleIdDisplay(cell.row.original)
  }
  // replace null values
  if (cell.value === null || cell.value === undefined) {
    return <div>-</div>
  }
  // format specific values
  if (cell.column.Header.includes('Duration')) {
    return formatDuration(cell.value);
  }
  if (cell.column.Header === 'Date/Time') {
    return tableDateFormat(cell.value);
  }
  if (cell.column.Header === 'Total' || cell.column.Header === 'Incidents') {
    return cell.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if (cell.column.Header.includes('-') && cell.column.Header.includes('/')) {
    // if column header is a date range, add commas
    return cell.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return cell.render('Cell')
  }

  // export const formatValue = (value, accessor) => {
  //   if (value === null || value === undefined) {
  //     return <div>-</div>
  //   }
  //   if (accessor.includes('duration')) {
  //     return formatDuration(value)
  //   }
  //   if (accessor === 'local_start') {
  //     return tableDateFormat(value);
  //   }
  //   if (accessor === 'total' || accessor === 'incidents') {
  //     return addCommas(value);
  //   }
  //   if (!!parseInt(accessor)) {
  //     // add - if parses to number or some logic to cover both weekly summary and map table ranges
  //     // accepts if part number pa\rt char, removes character
  //     // if column header is a date range or speed range, add commas
  //     // rc WIP
  //     return addCommas(value);
  //   }
  //   return value;
  //   }

  export const getFuelType = (v) => {
    if(v.is_bev) return 'BEV';
    if(v.is_phev) return 'PHEV';
    if(v.is_diesel) return 'Diesel';
    if(v.is_cng) return 'CNG';
    if(!v.is_bev && !v.is_phev) return 'Gasoline';
    return 'Unknown';
  }

  export const addCommas = (v,displayZeroes) => {
    if (displayZeroes && v === 0) return "0";
    if(!v)return '-'; // wont need this once switch to function throughout
    return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  export const formatDuration = (d) => {
    let val = Math.round(d);
    if(val === 0)return '<1';
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }