import React, { useEffect, useState } from 'react';
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import '../component-css/ionev-stylesheets/IonEV.css';
import Header from './ionev-components/Header';
import Landing from './ionev-components/Landing';
import About from './ionev-components/About';
import MapController from './ionev-components/MapController';
import UpdateLocationController from './ionev-components/UpdateLocationController';
import { validateAPIResponse, lastFullMonth, decideVinDisplay } from './ionev-components/UtilityFunctions';
import sortCandidateData, { calcTcoStats, processGraphTotals } from "./ionev-components/TcoHelpers";
import PageController from "./ionev-components/PageController";
import Loading from "./ionev-components/Loading";
import { vehicleIdDisplay, dateAsNonTZString } from './ionev-components/UtilityFunctions';
import { processApiResponse, processRateTime } from './ionev-components/utils/ConformUnits';
import FormatCell from './ionev-components/TableHelpers';
import { DateTime } from 'luxon';
import ActiveProcessingPage from './ionev-components/ProcessingPage';

const MAX_FAILURES_TO_UPDATE_PROCESSING_ALLOWED = 5;
const UPDATE_MILLISECONDS = 1000;

export default function IonEV(props){
    // eliminate this initial state set where possible once render sorted
    const [headerIsLoading, setHeaderIsLoading] = useState(true);
    const [dataIsLoading, setDataIsLoading] = useState(true);
    const [utcStartDate, setUtcStartDate] = useState(null);
    const [utcEndDate, setUtcEndDate ] = useState(null);
    const [eventStartDate, setEventStartDate] = useState(null);
    const [eventEndDate, setEventEndDate] = useState(null);
    const [formattedUtcStartDate, setFormattedUtcStartDate] = useState(null);
    const [formattedUtcEndDate, setFormattedUtcEndDate] = useState(null);
    const [formattedFleetStartDate, setFormattedFleetStartDate] = useState(null);
    const [formattedFleetEndDate, setFormattedFleetEndDate] = useState(null);

    const [activeLink, setActiveLink] = useState('dash');
    const [evCount, setEvCount] = useState('');
    //const [kwhByLocType, setKwhByLocType] = useState([]);
    const [chargeSummaryByLocation, setChargeSummaryByLocation] = useState([]);
    const [topLocs, setTopLocs] = useState([]);
    const [missedSummaryByLocation, setMissedSummaryByLocation] = useState([]);
    const [chargeSummaryByVcl, setChargeSummaryByVcl] = useState([]);
    const [missedSummaryByVcl, setMissedSummaryByVcl] = useState([]);
    const [chargeEvents, setChargeEvents] = useState([]);
    const [missedEvents, setMissedEvents] = useState([]);
    const [missedMiles, setMissedMiles] = useState(null);
    //const [totalActivityVcl, setTotalActivityVcl] = useState([]);
    const [totalActivitySummary, setTotalActivitySummary] = useState(null);
    const [totalActivityStats, setTotalActivityStats] = useState([]);
    //const [tcoData, setTcoData] = useState([]);
    const [tcoStats, setTcoStats] = useState({});
    const [tcoDataTotal, setTcoDataTotal] = useState([]);
    const [tcoStatsTotal, setTcoStatsTotal] = useState({});
    const [reimbursement, setReimbursement] = useState([]);
    const [reimbursementLastMth, setReimbursementLastMth] = useState([]);
    const [tcoTrend, setTcoTrend] = useState([]);
    const [locKwhStats, setLocKwhStats] = useState([]);
    const [settings, setSettings] = useState(props.dbSettings);
    const [kwRates, setKwRates] = useState([]);

    const [apiError, setApiError] = useState(false);
    const [isAggregateProcessing, setIsAggregateProcessing] = useState(null);
    const [isAnalyticsProcessing, setIsAnalyticsProcessing] = useState(null);
    const [processingCountdownFinished, setProcessingCountdownFinished] = useState(null);

    const dbDisplayName = props.dbDisplayName;
    const dbName = props.dbName;
    const BASE_URL = props.apiURL;
    const devState = props.devState;
    const user = props.user;
    const userSettings = user?.userSettings;
    const bearerToken = user.token;
    const location = useLocation();

    useEffect(() => {
      // on initialization, check the state of the aggregate processing, and then analytics processing, before fetching data
      getAggregateProcessingState().then(aggregateProcessing => {
        if (!aggregateProcessing) {
          getAnalyticsProcessingState().then(analyticsProcessing => {
            if (!analyticsProcessing) fetchSettingsData();
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (processingCountdownFinished) {
        let failures_to_update = 0;
        const interval = setInterval(async () => {
          const isProcessing = await getAnalyticsProcessingState();

          if (!isProcessing) {
            // Set various states when isProcessing is false
            setIsAnalyticsProcessing(false);
            setProcessingCountdownFinished(null);
            fetchSettingsData();
            return; // Exit early since we no longer need to continue the interval
          } else {
            failures_to_update += 1;
          }

          if (failures_to_update >= MAX_FAILURES_TO_UPDATE_PROCESSING_ALLOWED) {
            if (isProcessing) {
              setApiError(true); // If still processing after 5 seconds, set ApiError to true
            }
            clearInterval(interval); // Stop the interval after 5 seconds
          }
        }, UPDATE_MILLISECONDS); // Every 1 second

        return () => clearInterval(interval); // Cleanup
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processingCountdownFinished]); // Dependency array

    useEffect(() => {
      setDataIsLoading(true);
      fetchLandingData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [utcStartDate, utcEndDate])

    const handleChange = (accessor, value) => {
      //will need to do this non-generically...
      //setState({[accessor]: value});
      switch(accessor){
        case "activeLink":
          setActiveLink(value);
          break;
        case "startDate":
          setUtcStartDate(value);
          setFormattedUtcStartDate(processApiResponse(user.userSettings, {start: value}).start);
          break;
        case "endDate":
          setUtcEndDate(value);
          setFormattedUtcEndDate(processApiResponse(user.userSettings, {end: value}).end);
          break;
        default:
          return null;
      }
    }

    const isDaylightSavings = (date) => {
      const dstDates = [
        {start: '2024-03-10', end: '2024-11-03'},
        {start: '2025-03-09', end: '2025-11-02'},
        {start: '2026-03-08', end: '2026-11-01'},
      ];
      let dst = true;
      dstDates.forEach((d) => {
        if(date.ts >= DateTime.fromISO(d.start).setZone("UTC-7").ts // Use MT 
          && date.ts <= DateTime.fromISO(d.end).setZone("UTC-7").ts)dst = true;
      });
      return dst;
    }

    const getAggregateProcessingState = () => {
      const url = `${BASE_URL}isAggregateProcessing?dbName=${dbName}`;
      return fetch(url, { headers: { Authorization: `Bearer ${bearerToken}` } })
      .then(res => res.json())
      .then((data) => {
        let processing = data.data[0].aggregate_processing;
        setIsAggregateProcessing(processing);
        return Promise.resolve(processing);
      })
      .catch((err) => {
        console.error(err);
        setApiError(true);
        setIsAggregateProcessing(false);
        return Promise.reject(err);
      });
    };

    const getAnalyticsProcessingState = () => {
      const url = `${BASE_URL}isAnalyticsProcessing?dbName=${dbName}`;
      return fetch(url, { headers: { Authorization: `Bearer ${bearerToken}` } })
        .then(res => res.json())
        .then((data) => {
          let processing = data.data[0].analytics_processing;
          setIsAnalyticsProcessing(processing);
          return Promise.resolve(processing);
        })
        .catch((err) => {
          console.error(err);
          setApiError(true);
          return Promise.reject(err);
        });
    };

    const fetchSettingsData = () => {
      Promise.allSettled([
        fetch(`${BASE_URL}getPeriodObserved?&dbName=${dbName}`, {headers:{Authorization: `Bearer ${bearerToken}`}}),
        fetch(`${BASE_URL}getEvCount?clientIdent=${dbName}`, {headers:{Authorization: `Bearer ${bearerToken}`}}),
        fetch(`${BASE_URL}getVehicleClasses`, {headers:{Authorization: `Bearer ${bearerToken}`}}),
        fetch(`${BASE_URL}getKwhRates?dbName=${dbName}`, {headers: { Authorization: `Bearer ${bearerToken}` }})
      ])
      .catch(error => {
        console.error('Error in fetchSettingsData', error);
        setApiError(true);
      })
      .then(([boundsResponse, res2, res3, res4]) => {return Promise.allSettled([boundsResponse.value.json(), res2.value.json(), res3.value.json(), res4.value.json()])})
      .then(([boundsResponse, res2, res3, res4]) => {return [boundsResponse.value, res2.value, res3.value, res4.value]})
      .then(([bounds, data2, data3, data4]) => {
        const dates = processUTCDateBounds(bounds); // {'utcStart': utcStart, 'utcEnd': utcEnd, 'eventStart': eventStart, 'eventStart': eventEnd}
        var evs = validateAPIResponse(data2, true, {});
        var vcd = validateAPIResponse(data3, false, {});
        let rates = validateAPIResponse(data4, false, []);

        rates.forEach((r) => {
          r = processApiResponse(user.userSettings, r);
          r = processRateTime(user.userSettings, r);
      })
       setKwRates(rates);

        let formattedDates = processApiResponse(user.userSettings, {start: dates.eventStart, end: dates.eventEnd});

        // Conform units to user settings
        vcd = processApiResponse(user.userSettings, vcd);
        var evCount = "-";
        if(evs['bevs'] && evs['phevs'])evCount = parseInt(evs['bevs']) + parseInt(evs['phevs']);
        // only not switching fleet start/end to const because might be mutatable once groups implimented
        // good place to add default/backup map center (remove from elsewhere)

        settings['vehicle_class_defs'] = vcd; // add cdr class defs to settings obj

        setEventStartDate(dates.eventStart);
        setEventEndDate(dates.eventEnd);
        setUtcStartDate(dates.utcStart);
        setUtcEndDate(dates.utcEnd);
        setFormattedFleetStartDate(formattedDates.start);
        setFormattedFleetEndDate(formattedDates.end);
        setFormattedUtcStartDate(formattedDates.start);
        setFormattedUtcEndDate(formattedDates.end);
        setEvCount(evCount);
        setSettings(settings);
      })
      .then((data) => {
        fetchLandingData();
      })
      .catch(error => {
        console.error(error.message);
        setApiError(true);
      });
    }

  const fetchLandingData = () => {

    if(!eventStartDate || !eventEndDate || !utcStartDate || !utcEndDate)return;
    //local utility timestamp variables (abbrv/single letter to avoid name confusion with state variables)
    const s = dateAsNonTZString(utcStartDate);
    const e = dateAsNonTZString(utcEndDate);
    const fs = dateAsNonTZString(eventStartDate);
    const fe = dateAsNonTZString(eventEndDate);

    Promise.allSettled([
      fetch(`${BASE_URL}getChargeSummaryByLocation?&clientId=${dbName}&start=${s}&stop=${e}`, {headers:{ Authorization: `Bearer ${bearerToken}`}}),
      fetch(`${BASE_URL}getOpCostGraphTotals?&clientId=${dbName}&start=${fs}&end=${fe}&candidateType=ev`, {headers:{Authorization: `Bearer ${bearerToken}`}}),
      fetch(`${BASE_URL}getOpCostGraphTotals?&clientId=${dbName}&start=${fs}&end=${fe}&candidateType=ice`, {headers: {authorization: `Bearer ${bearerToken}`}}),
      fetch(`${BASE_URL}getMissedSummaryByLocation?&clientId=${dbName}&start=${s}&stop=${e}`, {headers: {authorization: `Bearer ${bearerToken}`}})
    ])
    .catch(error => {
      console.error('Error in fetchLandingData', error);
      setApiError(true);
    })
    .then(([res1, res2, res3, res4]) => {return Promise.allSettled([res1.value.json(), res2.value.json(), res3.value.json(), res4.value.json()]);
    })
    .then(([data1, data2, data3, data4]) => {return[data1.value, data2.value, data3.value, data4.value]})
    .then(([data1, data2, data3, data4]) => {
      // var kwhByLocType = validateAPIResponse(data1, false, []);
      var chargeSummaryByLocation = validateAPIResponse(data1, false, []); // can replace map getChargeSummaryByLocation (excp res)
      var missedSummaryByLocation = validateAPIResponse(data4, false, []);
      var topLocData = getTopLocData(chargeSummaryByLocation, missedSummaryByLocation);
      var opCostEv = validateAPIResponse(data2, true, []);
      var opCostIce = validateAPIResponse(data3, true, []);

      // Conform units to user settings
      chargeSummaryByLocation = chargeSummaryByLocation.map((i) => {return processApiResponse(userSettings, i)})
      missedSummaryByLocation = missedSummaryByLocation.map((i) => {return processApiResponse(userSettings, i)})
      opCostEv = processApiResponse(userSettings, opCostEv)
      opCostIce = processApiResponse(userSettings, opCostIce)

      chargeSummaryByLocation = formatDisplayAddress('address', chargeSummaryByLocation)
      missedSummaryByLocation = formatDisplayAddress('address', missedSummaryByLocation)

      var tcoTrend = processGraphTotals(opCostEv, opCostIce);
      setChargeSummaryByLocation(chargeSummaryByLocation);
      setMissedSummaryByLocation(missedSummaryByLocation);
      setTopLocs(topLocData);
      setTcoTrend(tcoTrend);
      setLocKwhStats(calcLocKwhStats(chargeSummaryByLocation, missedSummaryByLocation));

    })
    .then((data) => {
      fetchAppData();
    })
    .catch(error => {
      console.error(error, error.message);
      setApiError(true);
    });;
  }

  const fetchAppData = () => {
    //local utility timestamp variables (abbrv/single letter to avoid name confusion with state variables)
    const s = dateAsNonTZString(utcStartDate);
    const e = dateAsNonTZString(utcEndDate);
    // fleet start and end dates for TCO EPs (entire period)
    const fs = dateAsNonTZString(eventStartDate);
    const fe = dateAsNonTZString(eventEndDate);

    var queries = `&clientId=${dbName}&start=${s}&end=${e}`;
    var lastMth = lastFullMonth(fs, fe);
    var lastMthQueries = `&clientId=${dbName}&start=${lastMth['utcStart']}&end=${lastMth['utcEnd']}`;
    var tcoQueries = `&clientId=${dbName}&start=${fs}&end=${fe}`;
    Promise.allSettled([
      // NOTE removed &exclRes=true - getChargeEventsSummary, getChargeEvents, getMissedOppsSummary, getMissedOpps
      // filtered out where applicable if not admin
      fetch(`${BASE_URL}getTotalActivitySummary?&clientId=${dbName}&start=${s}&stop=${e}`, {headers:{ Authorization: `Bearer ${bearerToken}`}}),
      fetch(`${BASE_URL}getChargeEventsSummary?&clientId=${dbName}&start=${s}&stop=${e}`, {headers:{ Authorization: `Bearer ${bearerToken}`}}),
      fetch(`${BASE_URL}getMissedOppsSummary?&clientId=${dbName}&start=${s}&stop=${e}`, {headers:{ Authorization: `Bearer ${bearerToken}`}}),
      fetch(`${BASE_URL}getChargeEvents?&clientId=${dbName}&start=${s}&stop=${e}`, {headers:{ Authorization: `Bearer ${bearerToken}`}}),
      fetch(`${BASE_URL}getMissedOpps?&clientId=${dbName}&start=${s}&stop=${e}`, {headers:{ Authorization: `Bearer ${bearerToken}`}}),
      fetch(`${BASE_URL}getKmPerLiter?&clientId=${dbName}&start=${s}&stop=${e}`, {headers:{ Authorization: `Bearer ${bearerToken}`}}),
      fetch(`${BASE_URL}getCostComparisons?${queries}`, {headers:{Authorization: `Bearer ${bearerToken}`}}),
      fetch(`${BASE_URL}getCostComparisons?${tcoQueries}`, {headers:{Authorization: `Bearer ${bearerToken}`}}),
      fetch(`${BASE_URL}getReimbursementByLocation?${queries}`, {headers: {authorization: `Bearer ${bearerToken}`}}),
      fetch(`${BASE_URL}getReimbursementByLocation?${lastMthQueries}`, {headers: {authorization: `Bearer ${bearerToken}`}}),
    ])
    .catch(error => {
      console.error('Error in fetchAppData', error);
      setApiError(true);
    })
    .then(([res1, res2, res3, res4, res5, res6, res7, res8, res9, res10]) => {
      // Add catch to make sure everything was success.
      return Promise.allSettled([res1.value.json(), res2.value.json(), res3.value.json(), res4.value.json(), res5.value.json(), res6.value.json(), res7.value.json(), res8.value.json(), res9.value.json(), res10.value.json()]);
    })
    .then(([data1, data2, data3, data4, data5, data6, data7, data8, data9, data10]) => {return[ data1.value, data2.value, data3.value, data4.value, data5.value, data6.value, data7.value, data8.value, data9.value, data10.value]})
    .then(([data1, data2, data3, data4, data5, data6, data7, data8, data9, data10]) => {
      var totalSummary = validateAPIResponse(data1, false, []);
      var chargeSummary = validateAPIResponse(data2, false, []);
      var missedSummary = validateAPIResponse(data3, false, []);
      let chargeEvents = validateAPIResponse(data4, false, []);
      let missedEvents = validateAPIResponse(data5, false, []);
      let kmPerLiter = validateAPIResponse(data6, false, []);
      var tco = validateAPIResponse(data7, false, []);
      var tcoTotal = validateAPIResponse(data8, false, []);
      let reimbursement = validateAPIResponse(data9, false, []);
      let reimbursementLastMth = validateAPIResponse(data10, false, []);

      // Conform units to user settings
      totalSummary = totalSummary.map((i) => {return processApiResponse(userSettings, i)});
      chargeSummary = chargeSummary.map((i) => {return processApiResponse(userSettings, i)});
      missedSummary = missedSummary.map((i) => {return processApiResponse(userSettings, i)});
      chargeEvents = chargeEvents.map((i) => {return processApiResponse(userSettings, i)});
      missedEvents = missedEvents.map((i) => {return processApiResponse(userSettings, i)});
      kmPerLiter = kmPerLiter.map((i) => {return processApiResponse(userSettings, i)});
      tco = tco.map((i) => {return processApiResponse(userSettings, i)});
      tcoTotal = tcoTotal.map((i) => {return processApiResponse(userSettings, i)});
      reimbursement = reimbursement.map((i) => {return processApiResponse(userSettings, i)});
      reimbursementLastMth = reimbursementLastMth.map((i) => {return processApiResponse(userSettings, i)});

      var tcoData = sortCandidateData(tco, chargeSummary, missedSummary, settings);
      // tco values for entire period
      var tcoDataTotal = sortCandidateData(tcoTotal, chargeSummary, missedSummary, settings);
      var missedData = combineMissedSummary(missedSummary, kmPerLiter);

      // format vehicle asset id's and vins
      let formattedTotalSummary = formatVehicleIds(totalSummary);
      // in this loop or before processApiResponse would also potentially calcElecFuelEcon
      let formattedChargeSummary = formatVehicleIds(chargeSummary);
      let formattedMissedEvents = formatVehicleIds(missedEvents)
      let formattedMissedData = formatVehicleIds(missedData['byVcl'])
      let formattedChargeEvents = formatVehicleIds(chargeEvents);
      let formattedTcoDataTotal = formatVehicleIds(tcoDataTotal)

      //Attach a display address that will be used for the table data. Needs to formatted before passed to table for search bar to work.
      reimbursement = formatDisplayAddress('address_real', reimbursement);
      formattedChargeEvents = formatDisplayAddress('address', formattedChargeEvents);
      formattedMissedEvents = formatDisplayAddress('address', formattedMissedEvents);

      setTotalActivitySummary(formattedTotalSummary);
      setTotalActivityStats(calcTotalActivityStats(totalSummary));
      setChargeSummaryByVcl(formattedChargeSummary);
      setMissedSummaryByVcl(formattedMissedData);
      setMissedMiles(missedData['totalMissedMiles']);
      setChargeEvents(formattedChargeEvents);
      setMissedEvents(formattedMissedEvents);
      //setTcoData(tcoData);
      setTcoStats(calcTcoStats(tcoData));
      setTcoDataTotal(formattedTcoDataTotal);
      setTcoStatsTotal(calcTcoStats(tcoDataTotal));
      setReimbursement(reimbursement);
      setReimbursementLastMth(reimbursementLastMth);
      setHeaderIsLoading(false);
      setDataIsLoading(false);
    })
    .catch(error => {
      console.error(error.message);
      setApiError(true);
    });;
  }

  const getTopLocData = (charge, missed) => {
    // limit data to top 5 highest charge events
    var topCharge = charge.slice(0, 5);
    var topMissed = [];
    topCharge.forEach(l => {
      missed.forEach(m => {
        if(m.charge_loc_id === l.pkid)topMissed.push(m);
      });
    });
    return {topChargeLocs: topCharge, topMissedLocs: topMissed};
  }

  const calcLocKwhStats = (charge, missed) => {
    var kwh = 0;
    var missedKwh = 0;
    charge.forEach((l) => {
      kwh += l.charge_total_kwh;
    });
    missed.forEach((l) => {
      missedKwh += l.missed_total_kwh;
    });
    return {chargeLocKwh: kwh, missedLocKwh: missedKwh};
  }

  const calcTotalActivityStats = (totalSummary) => {
    // Note units have already been converted to respect user settings
    // regardless of miles/km label used in this function
    var totalMiles = 0;
    var elecMiles = 0;
    // var totalKwh = 0;
    var mKwh = 0;
    var dailyElecMiles = 0;
    var mKwhCount = 0;
    totalSummary.forEach((v) => {
      totalMiles += v.km;
      elecMiles += v.elec_km
      // totalKwh += v.blended_kwh
      dailyElecMiles += v.elec_km/parseInt(v.days);
      // don't want to include PHEVs in elec fuel efficiency values while
      // replacing with dash in table
      if (v.is_bev && v.blended_kwh > 0){
        // if BEV & has kwh value, calc elec miles / kwh and add to average
        var vclMKwh = v.elec_km/v.blended_kwh
        if (vclMKwh >= 0){
          // if mkwh is negative, leave out of average (consistent with table)
          mKwh += vclMKwh
          mKwhCount += 1
        }
      }
    });
    // NOTE we leave out zero kwh vehicles for efficiency average
    var avgMKwh = mKwh/mKwhCount
    // NOTE we include all vehicles for avg elec miles
    var avgElecMiles = elecMiles/totalSummary.length;  // avg per EV
    var avgDailyElecMiles = dailyElecMiles/totalSummary.length;  // avg per day per EV
    // NOTE ev count vs total summary length is not the same
    return {avgElecMiles: avgElecMiles, avgMKwh: avgMKwh,
            avgDailyElecMiles: avgDailyElecMiles, totalMiles: totalMiles};
  }

  const combineMissedSummary = (missedSummary, kmPerLiterRes) => {
    var totalMissed = 0;
    var missed = missedSummary.map(s => {
      var kmPerLiter = kmPerLiterRes.filter((m) => m.vin === s.vin);
      if (kmPerLiter && kmPerLiter[0]){
        kmPerLiter = kmPerLiter[0]['kms_per_liter'];
        var fuLiters = s.missed_elec_km/kmPerLiter;
      } else fuLiters = 0;
      s['mo_fu_liters'] = fuLiters;
      totalMissed += s.missed_elec_km;
      return s;
    });
    return {byVcl: missed, totalMissedMiles: totalMissed};
  }

  const formatVehicleIds = (data) => {
    if(data) {
     return data.map((v) => {
      //Need to preserve the original asset id value so it can be used in the excel downloads.
        v.original_asset_id = JSON.parse(JSON.stringify(v.asset_id))
        v.asset_id = vehicleIdDisplay(v)
        v.display_vin = decideVinDisplay(v)
        return v
      })
    }
  }

  const formatDisplayAddress = (accessor, data) => {
    if(data){
      return data.map((i) => {
        i.display_address = FormatCell(accessor, i[accessor], i, null, user, userSettings)
        return i
      })
    }
  }

  const fetchMapData = () => {
    fetchLandingData();
  }

  const processUTCDateBounds = (dates) => {

      if(!dates || !dates.data){
        return {'utcStart': DateTime.utc(), 'utcEnd': DateTime.utc()};
      };
      const o = dates.data[0];
      const eventOffset = isDaylightSavings(DateTime.now().setZone("UTC-7")) ? o.min_offset : o.max_offset;
      let zone = `UTC-0`;
      const utcStart = DateTime.fromMillis(o.min_epoch).setZone(zone);
      const utcEnd = DateTime.fromMillis(o.max_epoch).setZone(zone);
      let hours = eventOffset / 60;
      zone = (hours >= 0) ? `UTC+${hours}` : `UTC${hours}`;
      //add in the offset millis because the bespoke endpoint is sending midnight utc as a date part
      //the intention is we start at local midnight of the relevant day, not re-adjusted by zone
      //but we still want it to be timezone aware.
      const eventStart = DateTime.fromMillis(o.min_epoch + (-1*hours*60*60*1000)).setZone(zone);
      const eventEnd = DateTime.fromMillis(o.max_epoch + (-1*hours*60*60*1000)).setZone(zone);
      return {'utcStart': utcStart, 'utcEnd': utcEnd, eventStart: eventStart, eventEnd: eventEnd};
  }

  return (
    <div className="swt-ionev-app" data-testid={"ionev-wrapper-test-id"}>
      <Header
        fleetName={dbDisplayName}
        startDate={location.pathname === "/ionev/tco-comparison" ? null : utcStartDate} // don't show the min date in the date selector on the TCO page
        endDate={location.pathname === "/ionev/tco-comparison" ? null : utcEndDate} // don't show the max date in the date selector on the TCO page
        fleetStartDate={eventStartDate}
        fleetEndDate={eventEndDate}
        handleChange={handleChange}
        activeLink={activeLink}
        user={user}
        devState={devState}
        userSettings={userSettings}
        loading={headerIsLoading}
        apiError={apiError}
      />
      {(isAggregateProcessing || isAnalyticsProcessing || apiError) ?
        <div className="swt-processing-wrapper">
          <ActiveProcessingPage
            apiError={apiError}
            dbDisplayName={dbDisplayName}
            dbName={dbName}
            apiURL={BASE_URL}
            aggregateProcessing={isAggregateProcessing}
            setProcessingCountdownFinished={setProcessingCountdownFinished}
            bearerToken={bearerToken} />
        </div>
        :
        <div className="swt-ionev-page-body">
          {headerIsLoading || dataIsLoading ?
            <Loading />
            :
            <Switch>
              <Route exact path="/"><Redirect to="/ionev/"></Redirect></Route>
              <Route path="/ionev/" exact render={props =>
                <Landing
                  activeLinkChange={handleChange}
                  evCount={evCount}
                  tcoStats={tcoStats}
                  missedMiles={missedMiles}
                  fuelCost={settings['fuel_cost']}
                  avgDailyElecMiles={totalActivityStats['avgDailyElecMiles']}
                  userSettings={userSettings}
                />}
              />
              <Route path="/ionev/driving-activity/:vin" render={props => <PageController {...props}
                user={user}
                base_url={BASE_URL}
                dbName={dbName}
                startDate={utcStartDate}
                endDate={utcEndDate}
                activeLinkChange={handleChange}
                tableData={totalActivitySummary}
                totalActivitySummary={totalActivitySummary}
                totalActivityStats={totalActivityStats}
                evCount={evCount}
                dbDisplayName={dbDisplayName}
                formattedStartDate={formattedUtcStartDate}
                formattedEndDate={formattedUtcEndDate}
              />}
              />
              <Route path="/ionev/driving-activity" render={props => <PageController {...props}
                user={user}
                base_url={BASE_URL}
                dbName={dbName}
                startDate={utcStartDate}
                endDate={utcEndDate}
                activeLinkChange={handleChange}
                totalActivitySummary={totalActivitySummary}
                chargeSummaryByLocation={chargeSummaryByLocation}
                missedSummaryByLocation={missedSummaryByLocation}
                totalActivityStats={totalActivityStats}
                evCount={evCount}
                dbDisplayName={dbDisplayName}
                formattedStartDate={formattedUtcStartDate}
                formattedEndDate={formattedUtcEndDate}
              />}
              />
              <Route path="/ionev/missed-opportunities/:vin" render={props => < PageController {...props}
                user={user}
                base_url={BASE_URL}
                dbName={dbName}
                activeLinkChange={handleChange}
                missedEvents={missedEvents}
                missedSummaryByVcl={missedSummaryByVcl}
                fuelCost={settings['fuel_cost']}
                missedMiles={missedMiles}
                dbDisplayName={dbDisplayName}
                formattedStartDate={formattedUtcStartDate}
                formattedEndDate={formattedUtcEndDate}
              />}
              />
              <Route path="/ionev/missed-opportunities" render={props => < PageController {...props}
                user={user}
                base_url={BASE_URL}
                db={dbName}
                activeLinkChange={handleChange}
                missedSummaryByVcl={missedSummaryByVcl}
                fuelCost={settings['fuel_cost']}
                missedMiles={missedMiles}
                dbDisplayName={dbDisplayName}
                formattedStartDate={formattedUtcStartDate}
                formattedEndDate={formattedUtcEndDate}
              />}
              />
              <Route path="/ionev/charging-events/:vin" render={props => < PageController {...props}
                user={user}
                base_url={BASE_URL}
                db={dbName}
                activeLinkChange={handleChange}
                chargeEvents={chargeEvents}
                chargeSummaryByVcl={chargeSummaryByVcl}
                dbDisplayName={dbDisplayName}
                formattedStartDate={formattedUtcStartDate}
                formattedEndDate={formattedUtcEndDate}
              />}
              />
              <Route path="/ionev/charging-events" render={props => < PageController {...props}
                user={user}
                base_url={BASE_URL}
                db={dbName}
                activeLinkChange={handleChange}
                chargeSummaryByVcl={chargeSummaryByVcl}
                dbDisplayName={dbDisplayName}
                formattedStartDate={formattedUtcStartDate}
                formattedEndDate={formattedUtcEndDate}
              />}
              />
              <Route path="/ionev/events-by-location" render={props => < PageController {...props}
                user={user}
                base_url={BASE_URL}
                db={dbName}
                activeLinkChange={handleChange}
                chargeSummaryByLocation={chargeSummaryByLocation}
                missedSummaryByLocation={missedSummaryByLocation}
                topLocs={topLocs}
                locKwhStats={locKwhStats}
                dbDisplayName={dbDisplayName}
                formattedStartDate={formattedUtcStartDate}
                formattedEndDate={formattedUtcEndDate}
              />}
              />
              <Route path="/ionev/tco-comparison" render={props => < PageController {...props}
                user={user}
                base_url={BASE_URL}
                db={dbName}
                activeLinkChange={handleChange}
                tcoDataTotal={tcoDataTotal}
                tcoTrend={tcoTrend}
                totalActivitySummary={totalActivitySummary}
                tcoStatsTotal={tcoStatsTotal}
                dbDisplayName={dbDisplayName}
                formattedStartDate={formattedFleetStartDate}
                formattedEndDate={formattedFleetEndDate}
              />}
              />
              <Route path="/ionev/about" render={props => < About {...props}
                user={user}
                activeLinkChange={handleChange}
                settings={settings}
                kwRates={kwRates}
              />}
              />
              <Route path="/ionev/update-location" render={props => < UpdateLocationController {...props}
                user={user}
                base_url={BASE_URL}
                db={dbName}
              />}
              />
              <Route path="/ionev/map" render={props => < MapController {...props}
                user={user}
                base_url={BASE_URL}
                db={dbName}
                activeLinkChange={handleChange}
                handleChange={handleChange}
                totalActivitySummary={totalActivitySummary}
                chargeSummaryByLocation={chargeSummaryByLocation}
                missedSummaryByLocation={missedSummaryByLocation}
                chargeEvents={chargeEvents}
                missedEvents={missedEvents}
                fetchMapData={fetchMapData}
                startDate={utcStartDate}
                endDate={utcEndDate}
                fuelCost={settings['fuel_cost']}
              />}
              />
              <Route path="/ionev/reimbursement/:locId" render={props => < PageController {...props}
                user={user}
                base_url={BASE_URL}
                dbName={dbName}
                startDate={utcStartDate}
                endDate={utcEndDate}
                activeLinkChange={handleChange}
                reimbursementSummary={reimbursement}
                reimbursementLastMth={reimbursementLastMth}
                dbDisplayName={dbDisplayName}
                formattedStartDate={formattedUtcStartDate}
                formattedEndDate={formattedUtcEndDate}
              />}
              />
              <Route path="/ionev/reimbursement" render={props => < PageController {...props}
                user={user}
                base_url={BASE_URL}
                db={dbName}
                activeLinkChange={handleChange}
                reimbursementSummary={reimbursement}
                reimbursementLastMth={reimbursementLastMth}
                dbDisplayName={dbDisplayName}
                formattedStartDate={formattedUtcStartDate}
                formattedEndDate={formattedUtcEndDate}
              />}
              />
              {/* Catch-all route for undefined paths to redirect to landing page.
                    Note that this can really only happen when running the local repo - post-auth redirect prevents this in the dashboard
                    Adding this now for if we add support in the future for post-auth return to URL,
                    and to prevent weirdness when developing locally - NK 9/24
                */}
                <Route
                  render={() => 
                    <Redirect to="/ionev" />
                  }
                />
            </Switch>
          }
        </div>
      }
    </div>
  );
}
