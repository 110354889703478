import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { getLongMonth } from "../../utils/ezio-utils/GraphUtils";
import { formatTimeString } from '../../utils/ezio-utils/ConformUnits';

const hourQuarters = ["00", "15", "30", "45"];

const peakPointBackgroundColor = "rgba(3, 94, 91, .2)";
const offPeakPointBackgroundColor = "rgba(178, 177, 175,.8)";
const peakBackgroundColor = "rgba(3, 94, 91, .2)";
const offPeakBackgroundColor = "rgba(180, 180, 180, .04)";
const peakBorderColor = "rgba(3, 94, 91, .5)";
const offPeakBorderColor = "rgba(167, 166, 164, .8)";
const peakHoverBackgroundColor = "rgba(3, 94, 91, .7)";
const offPeakHoverBackgroundColor = "rgba(178, 177, 175, 1)";

type GraphDataset = {
  data: number[],
  backgroundColor?: string,
  hoverBackgroundColor?: string,
  borderColor?: string,
  pointBackgroundColor?: string,
  borderJoinStyle?: "miter",
  fill?: boolean,
  lineTension?: number,
  borderWidth?: number,
}

type GraphDataSorted = {
  labels?: string[],
  datasets: Array<GraphDataset>
}

export function standardizeData(demandData, smartChargeDemandData, peakShavedDemandData, selectedChargeType, userSettings) {
  if (!demandData || demandData.length < 1 || !smartChargeDemandData || smartChargeDemandData.length < 1) return null;
  
  let demandSorted = markPeaks(demandData);
  let smartSorted = markPeaks(smartChargeDemandData);
  // let peakShavedSorted = markPeaks(peakShavedDemandData);

  let labels = demandData[0].activity.map((_, idx) => formatTimeString(userSettings, Math.floor(idx / 4), hourQuarters[idx % 4]));

  let graphDataSets = [];
  if (selectedChargeType.value === 'unmanaged' || selectedChargeType.value === 'all') {
    demandSorted.forEach(d => {
      graphDataSets.push({
        label: `${d.localStart.split("T")[0]}-${Math.random()}`,
        data: d.activity,
        pointRadius: 1,
        pointHitRadius: 3,
        pointBackgroundColor: d.isPeak ? peakPointBackgroundColor : offPeakPointBackgroundColor,
        borderJoinStyle: "miter",
        fill: true,
        lineTension: 0.3,
        borderWidth: d.isPeak ? 2.5 : 1,
        backgroundColor: d.isPeak ? peakBackgroundColor : offPeakBackgroundColor,
        borderColor: d.isPeak ? peakBorderColor : offPeakBorderColor,
        hoverBackgroundColor: d.isPeak ? peakHoverBackgroundColor : offPeakHoverBackgroundColor,
        order: d.isPeak ? -1 : 1,
        datasetIdKey: 'unmanaged'
      });
    });
  }
  if (selectedChargeType.value === 'managed' || selectedChargeType.value === 'all') {
    smartSorted.forEach(d => {
      graphDataSets.push({
        label: `${d.localStart.split("T")[0]}-${Math.random()}`,
        data: d.activity,
        pointRadius: 1,
        pointHitRadius: 3,
        pointBackgroundColor: d.isPeak ? "rgba(180, 145, 15, .55)" : offPeakPointBackgroundColor,
        borderJoinStyle: "miter",
        fill: true,
        lineTension: 0.3,
        borderWidth: d.isPeak ? 2.5 : 1,
        backgroundColor: d.isPeak ? "rgba(180, 145, 15, .25)" : offPeakBackgroundColor,
        borderColor: d.isPeak ? "rgba(180, 145, 15, .8)" : offPeakBorderColor,
        hoverBackgroundColor: d.isPeak ? "rgba(180, 145, 15, .55)" : offPeakHoverBackgroundColor,
        order: d.isPeak ? -1 : 1,
        datasetIdKey: 'managed'
      });
    });
  }
  // Uncomment and modify the following section for peakShavedSorted when necessary
  // if (selectedChargeType.value === 'peakshaved' || selectedChargeType.value === 'all') {
  //   peakShavedSorted.forEach(d => {
  //     graphDataSets.push({
  //         label: `${d.localStart.split("T")[0]}-${Math.random()}`,//random num appended to make chartJS happy(dupe labels cause undefined metadata)
  //         data: d.activity,
  //         pointRadius: 1,
  //         pointHitRadius: 3,
  //         pointBackgroundColor: (d.isPeak) ? peakPointBackgroundColor : offPeakPointBackgroundColor,
  //         borderJoinStyle: "miter" as const,
  //         fill: true,
  //         lineTension: 0.3,
  //         borderWidth: (d.isPeak) ? 2.5 : 1,
  //         backgroundColor: (d.isPeak) ? "rgba(240, 105, 15, .05)" : offPeakBackgroundColor,
  //         borderColor: (d.isPeak) ? "rgba(240, 105, 15, .8)" : offPeakBorderColor,
  //         hoverBackgroundColor: (d.isPeak) ? peakHoverBackgroundColor : offPeakHoverBackgroundColor,
  //         order: (d.isPeak) ? -1 : 1
  //     });
  //   });
  // }

  return {
    labels: labels,
    datasets: graphDataSets,
  };
}

function markPeaks(data) {
  let max = -1;
  let peakIndex = -1;
  data.forEach((d, idx) => {
    d.isPeak = false;
    d.activity.forEach(a => {
      if (max <= a) {
        max = a;
        peakIndex = idx;
      }
    });
  });
  if (peakIndex > -1 && data[peakIndex]) data[peakIndex].isPeak = true;
  return data;
}

export default function GraphDaily({ demandData, smartChargeDemandData, peakShavedDemandData, selectedChargeType, userSettings }: any) {
  
  const [dailyPeaksGraphData, setDailyPeaksGraphData] = useState<GraphDataSorted>(null);
  
  useEffect(() => {
    const data = standardizeData(demandData, smartChargeDemandData, peakShavedDemandData, selectedChargeType, userSettings);
    setDailyPeaksGraphData(data);
  }, [demandData, smartChargeDemandData, peakShavedDemandData, selectedChargeType, userSettings]);

  const options = {
    transitions: {
      active: {
        animation: {
          duration: 0
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          afterTitle: (context: any) => {
            return `${context[0].raw} kW`
          },
          label: (context: any) => {
            const [year, month, day] = context.dataset.label.split("-").map((item: string) => parseInt(item))
            let mth = getLongMonth(month)
            return `${mth} ${day}, ${year}`
          }
        }
      },
      legend: {
        display: false
      },
      // title: {
      //   display: true,
      //   text: 'Daily kW Demand'
      // }
    },
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: "Peak kW"
        }
      },
      x: {
        display: true,
        title: {
          display: true,
          text: "Time of Day"
        },
        ticks: {
          maxTicksLimit: 24,
        },
      },
    }

  };

  return (dailyPeaksGraphData && options ? <Line
    id="daily-graph"
    width={1000}
    height={650}
    data={dailyPeaksGraphData}
    options={options}
  /> : <></>)

}