import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function CircularIndeterminate() {
  return (
    <Box sx={{ display: 'flex', color: "rgba(3, 94, 91, 0.8)", justifyContent: 'center' }}>
      <CircularProgress size="100px" color="inherit" />
    </Box>
  );
}