import { DateTime } from 'luxon';
import { formatTimestamp, ghgConversionForSummary } from './utils/ConformUnits'

// Function to check if a location is a HB, agnostic to whether it is a grouped location or not
export const checkHomebase = (homebaseId, parkingEntry) => {
  // If the parking entry is a grouped location and the homebase ID is in the list of location IDs, treat the grouped location as HB
  if (parkingEntry.grouped && parkingEntry.location_ids.includes(homebaseId)) return true
  // If the parking entry is not grouped, do logic as normal
  if (homebaseId === parkingEntry.pkid) return true;
  return false;
}

export function getFuelType(v){
    if(v.is_bev) return 'BEV';
    if(v.is_phev) return 'PHEV';
    if(v.is_diesel) return 'Diesel';
    if(v.is_cng) return 'CNG';
    // may want to switch this to rely on is_gasoline flag btu for now
    // it is a catch-all backup
    if(!v.is_bev && !v.is_phev) return 'Gasoline';
    return 'Unknown';
  }

export function decideVinDisplay (vcl) {
  if(typeof vcl.user_defined_vin === "undefined" || vcl.user_defined_vin === null || vcl.user_defined_vin === 'null' || !vcl.user_defined_vin) {
    return vcl.vin
  } else {
    return vcl.user_defined_vin
  }
}

export function vehicleIdDisplay (vcl) {
  if(vcl) {
  if (vcl.asset_id && vcl.asset_id !== 'null' && vcl.asset_id.toLowerCase() !== 'none') return vcl.asset_id
  if (vcl.user_defined_vin && vcl.user_defined_vin !== 'null') return vcl.user_defined_vin
  if (vcl.vin) return vcl.vin
  }
}

export function getOwnedStatus(vcl) {
  if (vcl.owned == null) {
      return '-';
  }
  else if (!vcl.owned) {
      return 'Leased';
  }
  else {
      return 'Owned';
  }
}

export const scoredOnExplanation = (input, scored_on, is_ld, pdfMode) => {
  if (!input || !scored_on) return;
  const vehicleCategory = is_ld ? 'Light Duty' : 'Medium and Heavy Duty';
  let str = pdfMode ? "*" : "";

  if (input === "No Change") {
    str += `This vehicle was compared to all available ${vehicleCategory} EVs, none of which were a good economic and operational fit. These scores are based on the EV that was the best fit: ${scored_on}.`;
  } else if (input === "Possible Sedan Fit") {
    str += `This vehicle was compared to all available EVs in its class, none of which were a good economic and operational fit. It was then compared against electric sedans and was identified to be a good fit for an electric sedan. These scores are based on the electric sedan model that was the best fit: ${scored_on}.`;
  } else if (input === "Operational Fit") {
    str += `This vehicle was compared to all available ${vehicleCategory} EVs, none of which were a good economic fit. However, at least one vehicle was a good operational fit. These scores are based on the EV that was the best operational fit: ${scored_on}.`;
  } else if (input === "No Models Fit") {
    str = `There are no EV candidates within this vehicle class. These scores are based on the EV that was the best fit: ${scored_on}.`;
  } else {
    str = null;
  }
  return str;
};

export function ymmDisplay(vcl) {
  if(!vcl) return
 const year = (parseInt(vcl.year) <= 1970) ? '' : vcl.year
 const make = (vcl.make && vcl.make !== 'null') ? vcl.make : ''
 const model = (vcl.model && vcl.model !== 'VIN Decoding Error' && vcl.model !== 'null') ? vcl.model : ''
 if (!year && !make && !model) {
   return null
 } else {
   return (
     `${year} ${make} ${model}`
   )
 }
 }


export function sortVehicles(a,b) {
  const aDisplay = vehicleIdDisplay(a);
  const bDisplay = vehicleIdDisplay(b);
  if (a.overall < b.overall) {
    return 1; // a has a lower score and should go after b
  } else if (a.overall > b.overall) {
    return -1; // a has a higher score and should go before b
  } else { // When overall scores are equal, sort alphanumerically based on display value
    if (aDisplay < bDisplay) {
        return -1; // aDisplay comes before bDisplay alphanumerically
    } else if (aDisplay > bDisplay) {
        return 1; // aDisplay comes after bDisplay alphanumerically 
    } else {
        return 0; // aDisplay and bDisplay are equal
    }
  }
}

export function calcEffectiveBattery(charge_to_ld,discharge_to_ld,charge_to_mdhd,discharge_to_mdhd,is_ld,usable_capacity) {
  if (usable_capacity === null || usable_capacity === undefined) return null;
  let charge_to = is_ld ? charge_to_ld : charge_to_mdhd;
  let discharge_to = is_ld ? discharge_to_ld : discharge_to_mdhd;
  let capacity_limit_kwh = 100 - charge_to + discharge_to;
  let capacity_limit_pct = (100-capacity_limit_kwh)/100;
  let effective_battery_kwh = usable_capacity * capacity_limit_pct;
  return Math.round(effective_battery_kwh*10)/10;
}

export function calculateProjectedSavings(activity) {
  let totalSavings = activity
    .filter((vehicle) => (vehicle.is_ev_recommendation))
    .reduce((previousValue, currentValue) => (previousValue += currentValue.rec_lt_net_savings), 0);
  var resultNegative = false;
  if (totalSavings > 0) {
    resultNegative = true;
  }
  totalSavings = Math.abs(totalSavings)
  if (totalSavings <= 0) {
    totalSavings = 0;
  }
  else if (totalSavings < 10000) {
    totalSavings = Math.round(Math.round(totalSavings / 1000) * 1000);
  }
  else {
    totalSavings = Math.round(Math.round(totalSavings / 10000) * 10000);
  }

  return totalSavings * (resultNegative ? -1 : 1);
}

export function calculateGhgReductionAmt(activity, userSettings) {
  let reduction = Math.round(activity
    .filter((vehicle) => (vehicle.is_ev_recommendation))
    .reduce((previousValue, currentValue) => (previousValue += currentValue.rec_lt_ghg_reduction_lbs), 0))
  return ghgConversionForSummary(userSettings, reduction)
}

// This function currently does not calculate what we want it to. If we decide to proceed with using percentages for the GHG reduction in the PDF, we would need to update the function to use all (selected) vehicles' GHG emissions AMOUNTS as the denominator, or ideally update the logic to use all vehicles' current GHG emissions as the denominator, not just their reductions amount.

// export function calculateGhgReductionPct(activity) {
//   let totalReduction = 0; // Sum of all reductions
//   let totalVehicles = 0; // Total number of vehicles

//   // Iterate through each activity
//   activity.forEach(vehicle => {
//     // Check if the activity item is a vehicle and has the is_ev_recommendation flag
//     if (vehicle.is_ev_recommendation) {
//       // Add its rec_lt_ghg_reduction_pct to totalReduction
//       totalReduction += vehicle.rec_lt_ghg_reduction_pct;
//     }
//     // Increment totalVehicles count for every vehicle
//     totalVehicles++;
//   });

//   // Calculate the average reduction percentage
//   const averageReduction = (totalReduction / (totalVehicles)/100;

//   return averageReduction; // Return the calculated average reduction
// }

export function calculateTotalDistance(activity) {
  return Math.round(activity.reduce((previousValue, currentValue) => (previousValue += (currentValue.obs_km ?? 0)), 0))
}

export const formatFrequency = (frequencyDecimal) => {
  const rawFrequency = frequencyDecimal * 100;
  return rawFrequency > 1 ? Math.round(rawFrequency) : '<1';
}

export const formatEnergyGraphData = (data, userSettings,) => {
  const sortedData = data.data?.sort((a, b) => (a.localized_date > b.localized_date ? 1 : -1)) || [];
  const rawMonthlyDataByVin = {};
  const formattedMonthlyDataByVin = {};

  sortedData.forEach(d => {
    const vin = d.vin;
    const [year, month] = d.localized_date.split('T')[0].split('-');
    const monthYear = `${month}/${year}`;

    // Create the VIN group if it does not exist
    if (!rawMonthlyDataByVin[vin]) {
      rawMonthlyDataByVin[vin] = {};
      rawMonthlyDataByVin[vin].rawData = {}
    }

    // Create the date group if it does not exist for the VIN
    if (!rawMonthlyDataByVin[vin].rawData[monthYear]) {
      rawMonthlyDataByVin[vin].rawData[monthYear] = [];
    }

    // Add the data object to the appropriate month/year group
    rawMonthlyDataByVin[vin].rawData[monthYear].push(d);
  });

  // process the data from its raw daily summary format to one usable in the energy use graph
  for (const vin in rawMonthlyDataByVin) {
    const { rawData } = rawMonthlyDataByVin[vin];
    let dateRangeLimited = false;
    let formattedData = [];

    // limit the date range to the most recent 12 months if more data than that is provided
    let months = Object.entries(rawData);
    if (months.length > 12) {
      dateRangeLimited = true;
      months = months.slice(-12);
    }

    // for each month of data, grab the avg & max kwh and date the max occured
    // push to a new object that's readable by recharts
    months.forEach(([month, data]) => {
      const avg = data.reduce((acc, item) => acc + item.syn_kwh, 0) / data.length;
      const max = Math.max(...data.map(item => item.syn_kwh));
      const maxDate = data.find(item => item.syn_kwh === max).localized_date.replace("Z", ""); // removing Z from localized_date to prevent timezone conversion
      const formattedMonth = formatTimestamp(userSettings, DateTime.fromFormat(month, 'MM/yyyy'), true, true).date;
      const formattedMaxDate = formatTimestamp(userSettings, DateTime.fromISO(maxDate)).date ;
      formattedData.push({
        month: formattedMonth,
        avg,
        max,
        maxDate: formattedMaxDate
      });
    });

    formattedMonthlyDataByVin[vin] = {
      formattedData,
      dateRangeLimited
    }
  }
  return formattedMonthlyDataByVin;
}

export const calculateTotalParkingEvents = (parkingLocs) => {
  return parkingLocs?.reduce(function (prev, p) { return prev + parseInt(p.count) }, 0) ?? 0; // if parkingLocs doesn't exist, return 0 parking events.
}