import EzevFx from "./components/swt-ezev-fx";
import EzevTd from "./components/swt-ezev-td";
import Emit from "./components/swt-emit";
// import AltTransportationMap from "./components/swt-alt-map";
// import EZIO from "./components/swt-ezio";
import IonEV from "./components/swt-ionev";
import Shifted from "./components/swt-shifted";
import Speedn from "./components/swt-speedn";
import Ezio from "./components/swt-ezio";
import Fueln from "./components/swt-fueln";
import Idling from './components/swt-idlin';

class ComponentRegistry {
  // NOTE this file does not copy over from dashboard-core in deploy
    constructor(){
        this.EzevTd = EzevTd;
        this.EzevFx = EzevFx;
        this.Emit = Emit;
        // this.AltTransportationMap = AltTransportationMap;
        // this.EZIO = EZIO;
        this.IonEv = IonEV;
        this.Shifted = Shifted;
        this.Ezio = Ezio;
        this.Speedn = Speedn;
        this.Fueln = Fueln;
        this.Idling = Idling;

        this.register = [
            {"name": "EzevTd", "path": "/ezev-td"},
            {"name": "EzevFx", "path": "/ezev-fx"},
            {"name": "Emit", "path": "/emit"},
            {"name": "Shifted", "path": "/shifted"},
            {"name": "IonEv", "path": "/ionev"},
            {"name": "Ezio", "path": "/ezio"},
            {"name": "Speedn", "path": "/speedn"},
            {"name": "Fueln", "path": "/fueln"},
            {"name": "Idling", "path": "/idling"},
            ]
        return null;
    }

}
/*
<PrivateRoute path="/protected" component={EzIO} auth={authenticated} />
<PrivateRoute path="/ezev"  component={Ezev} auth={authenticated} db={this.getCurrentDb} secrets={this.secrets} devState={this.state.devState} user={this.state.user} apiURL={this.state.apiURL} />
<PrivateRoute path="/emit" component={Emit} auth={authenticated} db={this.getCurrentDb} />
<PrivateRoute path="/ionev" component={IonEV} auth={authenticated} db={this.getCurrentDb} apiURL={this.state.apiURL} devState={this.state.devState} user={this.state.user} is_admin={this.state.user.admin} />
<PrivateRoute path="/shifted" component={Shifted} auth={authenticated} db={this.getCurrentDb()} secrets={this.secrets} devState={this.state.devState} user={this.state.user} />
<PrivateRoute path="/alt-map" component={AltTransportationMap} auth={authenticated} />
<PrivateRoute path="/ezio" component={EZIO} auth={authenticated} db={this.getCurrentDb()} devState={this.state.devState} admin={this.state.admin} user={this.state.user} apiURL={this.state.apiURL} />
<PrivateRoute path="/install-tracker" component={InstallTracker} auth={authenticated} dbName={this.getCurrentDb()} secrets={this.secrets} user={this.state.user} apiURL={this.state.apiURL}/>
*/

export default ComponentRegistry;
