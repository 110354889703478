import Select from "react-select"
import React from "react"
import * as CS from "../../styles/ezio-styles/Controls-styles"
import { formatOptionLabel } from "../../utils/ezio-utils/SelectUtils"

type ChargeTypeDropdownProps = {
    selectedChargeType: OptionType,
    onChange: Function
}

export type OptionType = {
    value: string;
    label: string;
};

export default function ChargeTypeDropdown({ selectedChargeType, onChange }: ChargeTypeDropdownProps) {
    const options: OptionType[] = [
        { value: 'unmanaged', label: 'Unmanaged' },
        { value: 'managed', label: 'Time of Use Managed' },
        // { value: 'peakshaved', label: 'Peak Shaved'},
        { value: 'all', label: 'Comparison'}

    ];

    return (
        <CS.ChargeTypeWrapper>
            <Select
                isSearchable={false}
                hideSelectedOptions={false}
                value={selectedChargeType}
                options={options}
                onChange={(event) => { onChange(event) }}
                styles={
                    {
                        valueContainer: (base: any) => ({
                            ...base,
                            border: "2px solid #F5F5F5",
                            borderRadius: "5px",
                            fontSize: "18px",
                            cursor: "pointer",
                            height: "38px",
                        }),
                        container: CS.SelectStyles.container,
                        control: CS.SelectStyles.control,
                        option: CS.SelectStyles.option,
                        noOptionsMessage: CS.SelectStyles.noOptionsMessage,
                        menu: (base: any) => ({
                            ...base,
                            marginTop: "0",
                        }),
                    }
                }
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: CS.CustomDownChevron,
                    ClearIndicator: () => null
                }}
                formatOptionLabel={formatOptionLabel}
            />
        </CS.ChargeTypeWrapper>
    )
}