import React, { useState, useEffect, Suspense } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import HeaderSingleVcl from './HeaderSingleVcl';
import { processApiResponse } from './utils/ConformUnits';
import { calcEffectiveBattery, scoredOnExplanation } from './HelperFunctions';
import Loading from './loading';

// code-split imports
const SingleVclPage = React.lazy(() => import("./SingleVclPage"));

const SingleVclController = (props) => {
  const { activity } = props;
  const [vclResults, setVclResults] = useState(null);
  const [candidateResults, setCandidateResults] = useState(null);
  const [vclFuelingData, setVclFuelingData] = useState(null);
  const [energyGraphData, setEnergyGraphData] = useState(null);
  const [transactionCount, setTransactionCount] = useState(null);

  const history = useHistory();
  const location = useLocation();
  const { vin } = useParams();

  useEffect(() => {
    props.getAnalyticsProcessingState(false);
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activity, props.fuelingData, props.energyGraphData]);

  const getData = () => {
    const vinFromPath = vin;

    // Check if location.state exists; if not, use the VIN from the pathname
    if (!location.state && vinFromPath) {
      location.state = { vcl: vinFromPath };  // Set location.state manually if not present
    }

    // If still no state or activity, exit the function
    if (!location.state || !activity || !props.energyGraphData || !props.fuelingData) return;
    
    // If vehicle data is set, fetch candidate and fueling data
    if (setVclData()) {
      getCandidateData();
      getFuelingData();
      getEnergyGraphData();
    }
  };

  const setVclData = () => {
    const vehicleVin = location.state.vcl;
    const vcl = props.activity?.find(v => v.vin === vehicleVin);

    if (vcl) {
      setVclResults(vcl);
      return true;
    } else {
      console.error("Vehicle not found. Redirecting to landing page...");
      history.push('/');
      return false;
    }
  };

  const getCandidateData = () => {
    const vcl = location.state.vcl;
    const url = `${props.apiURL}getezevcandidateresults`;
    const queries = `?ident=${props.dbName}&vin=${vcl}`;

    fetch(`${url}${queries}`, {
      headers: { 'Authorization': `Bearer ${props.user.token}` }
    })
      .then(res => res.json())
      .then(data => {
        const candidatesData = data?.data?.[0]?.json || {};  
        const { bevs = [], phevs = [] } = candidatesData;

        // Process each candidate list and map their upfits
        [bevs, phevs].forEach((candidates) => {
          candidates.forEach(d => {
            const cand = props.candidates.find(c => c.id === d.id);
            if (cand?.upfits) d.upfits = cand.upfits;
            processApiResponse(props.user.userSettings, d);
          });
        });
        setCandidateResults(candidatesData);
      })
      .catch(error => console.error("Error fetching candidate data: ", error));
  };

  const getEnergyGraphData = () => {
    if (!location.state.vcl || !props.energyGraphData) return;
    const vin = location.state.vcl;
    const vehicleEnergyGraphData = props.energyGraphData[vin] ?? [];
    setEnergyGraphData(vehicleEnergyGraphData);
  }

  const getFuelingData = () => {
    if (!location.state.vcl || !props.fuelingData || !props.vehicleTransactionCounts) return;
    const vin = location.state.vcl;
    const vehicleFuelingData = props.fuelingData[vin] ?? [];
    const transactionCount = props.vehicleTransactionCounts[vin] ?? 0;

    // Set fueling data for the vehicle
    setVclFuelingData(vehicleFuelingData);
    setTransactionCount(transactionCount);
  };

  let component;
  if (!vclResults || !candidateResults || !vclFuelingData) {
     // Display a loading state if data hasn't been fetched yet
    component = (
      <>
        <HeaderSingleVcl vcl={{ asset_id: '' }} />
        <div className="ezev-loading-wrapper">
          <Loading />
        </div>
      </>
    );
  } else {
    let explanation = scoredOnExplanation(vclResults.recommendation, vclResults.scored_on, vclResults.is_ld, false);

    // Combine and map candidate results to include PHEVs and BEVs
    let candResults = [
      ...candidateResults.phevs.map(item => ({ ...item, is_phev: true, is_bev: false })),
      ...candidateResults.bevs.map(item => ({ ...item, is_phev: false, is_bev: true }))
    ];

    const iceCandidate = props.candidates.find(c => c.ymm === vclResults?.ice_comp_ymm);
    // Map effective battery kWh to candidates
    candResults.forEach(c => {
      const cand = props.candidates.find(e => e.id === c.id) || props.candidates.find(e => e.ymm === c.ymm);
      if (!cand) {
        c.effective_battery_kwh = '-';
        return;
      }
      c.price = cand.net_price;
      c.usable_kwh = cand.usable_kwh !== undefined ? Math.round(cand.usable_kwh * 10) / 10 : '-';
      c.effective_battery_kwh = calcEffectiveBattery(props.settings.charge_to_ld, props.settings.discharge_to_ld, props.settings.charge_to_mdhd, props.settings.discharge_to_mdhd, vclResults.is_ld, cand.usable_kwh);
    });

    component = (
      <SingleVclPage
        vcl={vclResults}
        scoredOnText={explanation}
        localKwh={props.localKwh}
        fueling={vclFuelingData}
        cands={candResults}
        iceCandidate={iceCandidate}
        settings={props.settings}
        userSettings={props.user.userSettings}
        dbDisplayName={props.dbDisplayName}
        apiURL={props.apiURL}
        user={props.user}
        energyGraphData={energyGraphData}
        products={props.products}
        transactionCount={transactionCount}
        cobrandingLogoUrl={props.cobrandingLogoUrl}
      />
    );
  }

  return (
    <div>
      <Suspense fallback={<HeaderSingleVcl vcl={{ asset_id: '' }} />}>
        {component}
      </Suspense>
    </div>
  );
};

export default SingleVclController;