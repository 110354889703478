import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import AdminTableView from "./AdminTableView";
import Loading from "../swt-loading";
import Search from "./search";
import * as S from "../../../../styles/core-styles/AdminTools-styles";
import { _stringSort } from "./TableHelpers";
import DatabaseStatusDropdown from "./DatabaseStatusDropdown";

const SECS_PER_MIN = 60;
const SECS_PER_HOUR = SECS_PER_MIN * 60; // 3600 seconds per hour
const SECS_PER_DAY = SECS_PER_HOUR * 24; // 86400 seconds per day

export default function SuperAdminDatabasesTable(props){
    const PROCESS_HOURS_LIMIT = 6;
    const PERIODIC_HOURS_LIMIT = 12;
    const ANALYTCIS_HOURS_LIMIT = 25;
    const AGGREGATE_HOURS_LIMIT = 25;


    const { apiURL, user } = props;
    
    const skipPageResetRef0 = useRef(true);
    const skipPageResetRef1 = useRef(true);

    const [databases, setDatabases] = useState(null);
    const [now, setNow] = useState(new Date());
    const [refresh, setRefresh] = useState(true);
    //const [immutableDatabases, setImmutableDatabases] = useState(null);
    const [matchingDatabases, setMatchingDatabases] = useState(null);
    useEffect(() => {
        const interval = setInterval(() => {
            skipPageResetRef0.current = false
            setNow(new Date());
          }, 1000);
          return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            skipPageResetRef1.current = false
            setRefresh(true);
          }, 60000);
          return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        skipPageResetRef0.current = true
    }, [now]);

    useEffect(() => {
        skipPageResetRef1.current = true
    }, [refresh]);

    const boolSort = useMemo(() => (rowA, rowB, columnId) => {
        const a = rowA.values[columnId].props.bool;
        const b = rowB.values[columnId].props.bool;
        return a > b ? 1 : -1;
    },[]);

    const dateSort = useMemo(() => (rowA, rowB, columnId) =>{
        const a = rowA.values[columnId].props.epoch;
        const b = rowB.values[columnId].props.epoch;
        return a > b ? 1 : -1;
    }, []);

    const statusSort = useMemo(() => (rowA, rowB, columnId) =>{
        const a = rowA.values[columnId].props.status;
        const b = rowB.values[columnId].props.status;
        return a > b ? 1 : -1;
    }, []);

    const updateSort = useMemo(() => (rowA, rowB, columnId) => {
        const a = rowA.values[columnId].props.defaultValue;
        const b = rowB.values[columnId].props.defaultValue;
        
        // Define sorting priority based on the desired order:
        const getPriority = (updateStatus) => {
            if (updateStatus === "update") return 1; // Data & UI updating sorts first
            if (updateStatus === "noDataUpdate") return 2; // UI updating only sorts second
            if (updateStatus === "noUiUpdate") return 2; // no updates at all sorts third
            if (updateStatus === "archived") return 4; // archived sorts last
        };
        
        const priorityA = getPriority(a);
        const priorityB = getPriority(b);
        return priorityA - priorityB;
    }, []);


    const secondsToReadableString = (seconds) => {
        if(seconds > (SECS_PER_DAY - 1))return `${parseFloat(seconds / SECS_PER_DAY).toFixed(0)} Days`;
        if(seconds > (SECS_PER_HOUR - 1))return `${parseFloat(seconds / SECS_PER_HOUR).toFixed(1)} Hours`;
        if(seconds > (SECS_PER_MIN - 1))return `${parseFloat(seconds / SECS_PER_MIN).toFixed(0)} Minutes`;
        return `${parseFloat(seconds).toFixed(0)} Seconds`;
    }

    const databaseStatus = (seconds, update, trip_seconds, analytics_seconds, aggregate_sconds) => {
        if(!seconds || !update || !trip_seconds || !analytics_seconds)return(<S.CDRStatusMarker status={3} backgroundColor="#a9a9a9"></S.CDRStatusMarker>);
        if(seconds > (SECS_PER_DAY - 1))return(<S.CDRStatusMarker status={0}  backgroundColor="#e76f51"></S.CDRStatusMarker>);
        if(seconds > (SECS_PER_HOUR*PERIODIC_HOURS_LIMIT) ||
           trip_seconds > (SECS_PER_HOUR*PROCESS_HOURS_LIMIT) ||
           analytics_seconds > (SECS_PER_HOUR*ANALYTCIS_HOURS_LIMIT) ||
           aggregate_sconds > (SECS_PER_HOUR*AGGREGATE_HOURS_LIMIT)) return(<S.CDRStatusMarker status={1} backgroundColor="#e9c46a"></S.CDRStatusMarker>);
        return(<S.CDRStatusMarker status={2} backgroundColor = "#2a9d8f"></S.CDRStatusMarker>);
    }

    const tableColumns = [
        {Header: "Status", accessor: "status", sortType: statusSort, width: 100},
        {Header: "Database Id", accessor: "name", sortType: _stringSort, width: 250, prevColWidth: '100px'},
        {Header: "Display Name", accessor: "display_name", sortType: _stringSort},
        {Header: "Controller", accessor: "controller", sortType: _stringSort, width: 150},
        {Header: "IP Address", accessor: "address", sortType: _stringSort, width: 200},
        {Header: "Integration", accessor: "integration", sortType: _stringSort, width: 150},
        {Header: "Update Status", accessor: "updateStatus", sortType: updateSort, width: 360},
        {Header: "Last Status Change", accessor: "last_status_edit_ts", sortType: boolSort, width: 215}, 
        {Header: "Last Status Editing User", accessor: "last_status_edit_email", sortType: boolSort},
        {Header: "Periodic Updated", accessor: "periodic_updated", sortType: dateSort},
        {Header: "Telemetry Processed", accessor: "trips_updated", sortType: dateSort},
        {Header: "Analytics Updated", accessor: "analytics_updated", sortType: dateSort},
        {Header: "Aggregate Updated", accessor: "aggregate_updated", sortType: dateSort},
        {Header: "Incomplete Vehicles", accessor: "incomplete_vcls", sortType: "basic"},
        {Header: "Setup Approved", accessor: "setup_approved", sortType: 'basic'},
        {Header: "Archive", accessor: "archived", disableSortBy: true, width: 200},
    ]
    
    useEffect(()=>{
        if (refresh === false ) return;
        try {
            fetch(`${apiURL}getDatabases`, {
                headers: { Authorization: `Bearer ${user.token}` },
            })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.status === "error")
                        alert("There was a server error during your request.");
                    else {
                        setDatabases(data.data);
                        setRefresh(false);
                        //setImmutableDatabases(JSON.parse(JSON.stringify(data.data)));
                    }
            });
        } catch (err) {
            console.error("error", err);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[apiURL, user, refresh]);

    const DateTableCell = useCallback((props)=> 
        <div
            id={props.id}
            onClick={props.handleOnClick}
            title={props.epoch ? secondsToReadableString((now.getTime() + (now.getTimezoneOffset() * SECS_PER_MIN * 1000) - props.epoch)/1000): null}
        >
            {props.epoch ? secondsToReadableString((now.getTime() + (now.getTimezoneOffset() * SECS_PER_MIN * 1000) - props.epoch)/1000): null}
        </div>,[now]
        );

    const updateDatabaseStatus = (status, d) => {
        let db = databases.find(e => e.name === d.name);
        let dataUpdateStatus = true;
        let uiUpdateStatus = true;
        if (status === "noDataUpdate") dataUpdateStatus = false;
        if (status === "noUiUpdate") {
            dataUpdateStatus = false;
            uiUpdateStatus = false;
        }
        try{
            const url = `${props.apiURL}updateDatabaseStatus`;
            const body = {dbName: db.name, dataUpdateStatus: dataUpdateStatus, uiUpdateStatus: uiUpdateStatus, editingUserEmail: user.email}
            fetch(url, {
                headers: { 
                    Authorization: `Bearer ${props.user.token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                method: "POST",
                body: JSON.stringify(body)
            })
            .then((data) => {
                if (data.status === "error") {
                    alert("There was a server error during your request.");
                } else {
                    setRefresh(true);
                    if(d.name === props.activeDb) props.getDbUiUpdateStatus(props.activeDb); // if the user has changed update settings on the database they're currently logged in to, we need to pass that information back up to the top of the app, to potentially trigger the 'no update' warning in settings.
                }
            });
          } catch (err) {
            console.error(err);
          }
    }

    const archiveDatabase = (d) => {
        if (window.confirm(`Are you sure you want to archive database ${d.name}?`)) {
            let db = databases.find(e => e.name === d.name);
            try{
                const url = `${props.apiURL}archiveDatabase`;
                const body = {dbName: db.name, editingUserEmail: user.email}
                fetch(url, {
                headers: { 
                    Authorization: `Bearer ${props.user.token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                method: "POST",
                body: JSON.stringify(body)
                })
                .then((data) => {
                    if (data.status === "error") {
                        alert("There was a server error during your request.");
                    } else {
                        setRefresh(true);
                        if(d.name === props.activeDb) props.getDbUiUpdateStatus(props.activeDb); // if the user has changed update settings on the database they're currently logged in to, we need to pass that information back up to the top of the app, to potentially trigger the 'no update' warning in settings.
                    }
                });
            } catch (err) {
                console.error(err);
            }
        };
    }

    const TableButton = useCallback((props) => 
        <S.InnerTableButton
            color={props.color}
            onClick={props.handleClick}
            disabled={props.disabled}
            title={props.title}
        >
            {props.label}
        </S.InnerTableButton>
    ,[])

    const mappedDatabases = useMemo(()=>{
        if(!databases || !matchingDatabases)return null;
        if(databases.length < 1)return [];

        const dbs = JSON.parse(JSON.stringify(databases));
        const matchingDbs = matchingDatabases.map((d) => {return d.name});
        return dbs.filter((d) => {
            if(matchingDbs.indexOf(d.name) < 0)return null;
            
            let updateStatus = null;
            if (d.update && d.ui_updating) updateStatus = "update";
            else if (!d.update && d.ui_updating) updateStatus = "noDataUpdate";
            else if (!d.update && !d.ui_updating && !d.archived) updateStatus = "noUiUpdate";
            else if (d.archived) updateStatus = "archived";
            d.integration = d.integration ? d.integration : "Not Assigned";
            d.setup_approved = d.setup_approved === true ? 'True' : 'False';
            d.updateStatus = <DatabaseStatusDropdown
                value={updateStatus}
                handleChange={(e)=>updateDatabaseStatus(e,d)}
                id={d.name}
                disabled={d.archived ? true : false}
            />
            d.periodic_updated = <DateTableCell epoch={d.periodic_updated ? Date.parse(d.periodic_updated) : null} accessor={"periodic_updated"} handleOnClick={function(){}}/>
            d.trips_updated = <DateTableCell epoch={d.trips_updated ? Date.parse(d.trips_updated) : null} accessor={"trips_updated"} handleOnClick={function(){}}/>
            d.analytics_updated = <DateTableCell epoch={d.analytics_updated ? Date.parse(d.analytics_updated) : null} accessor={"analytics_updated"} handleOnClick={function(){}}/>
            d.aggregate_updated = <DateTableCell epoch={d.aggregate_updated ? Date.parse(d.aggregate_updated) : null} accessor={"aggregate_updated"} handleOnClick={function(){}}/>
            d.archived = <TableButton label={"Archive"} color={'#e07c1f'} handleClick={(e)=>archiveDatabase(d)} disabled={d.archived} title={d.archived ? "This database has already been archived!" : null}/>
            d.last_status_edit_ts = <DateTableCell epoch={d.last_status_edit_ts ? Date.parse(d.last_status_edit_ts) : null} accessor={"last_edit_ts"} handleOnClick={function(){}}/>
            d.status = databaseStatus(
                            (now.getTime() + (now.getTimezoneOffset() * SECS_PER_MIN * 1000) - d.periodic_updated.props.epoch)/1000,  
                            d.update, 
                            (now.getTime() + (now.getTimezoneOffset() * SECS_PER_MIN * 1000) - d.trips_updated.props.epoch)/1000, 
                            (now.getTime() + (now.getTimezoneOffset() * SECS_PER_MIN * 1000) - d.analytics_updated.props.epoch)/1000,
                            (now.getTime() + (now.getTimezoneOffset() * SECS_PER_MIN * 1000) - d.aggregate_updated.props.epoch)/1000
                        );
            return d;
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [databases, matchingDatabases, now]);
    return(
        <>
            <S.CandidatesTableHeaderContainer>
                <div>
                    <S.AdminTableExplainTextPrimary>List of all databases.</S.AdminTableExplainTextPrimary>
                    <S.AdminTableExplainTextSub>Click a column name to sort the table.</S.AdminTableExplainTextSub> 
                    <S.SearchContainer>
                        <Search allValues={databases} setMatchingValues={setMatchingDatabases} disabled={databases ? false : true}/>
                    </S.SearchContainer>
                </div>
            </S.CandidatesTableHeaderContainer>
                    {!mappedDatabases && <Loading />}
                    {(mappedDatabases) && 
                        <AdminTableView 
                            columns={tableColumns}
                            data={mappedDatabases}
                            stickyCols={2}
                            sortBy={{id: "status", desc: false}}
                            skipPageResetRef0={skipPageResetRef0}
                        />
                    }
        </>
    )
}