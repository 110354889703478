import React from "react";
import SingleSelectDropdown from "./SingleSelectDropdown";
import { SearchSelect } from '@sawatchlabs/swt-components';


export default function DropdownSelector(props) {
  return (
    <SingleSelectDropdown
      className={props.selectorName + " ezev-UI-Text-light"}
      placeholder={props.value?.name}
      value={{value: props.value ? props.value.id : "", label: props.value?.name}} // Assigning to empty string prevents react error (switching input from uncontrolled to controlled)
      onChange={props.handleSelectorChange}
      options={props.options}
      inputId={props.inputId}
      name={props.name}
    />
  );
}

// determine if a candidate satisfies all vehicle upfits, to ensure proper grouping in dropdown
const upfitsMatch = (cand, vcl) => {
  if (vcl.upfits.length === 0) return true; // return true if upfits do not apply
  let upfitsMatch = true;

  vcl.upfits.forEach((upfit) => {
    let candUpfitIds = cand.upfits.map(u => u.id);
    if(candUpfitIds.includes(upfit.id)) return; // if the candidate has the vehicle upfit, continue the loop
    else upfitsMatch = false; // if candidate is missing any one upfit, set flag to false
  });

  return upfitsMatch;
}

const formatCandidateOptions = (options, props) => {
  //filteredOptions is used to filter out the recommended replacement so we dont list it twice in dropdown options.
  const filteredOptions = options.filter((cand) => {return cand.ymm !== props.recommendation})
  const inClassCands = filteredOptions.filter((cand) => {return cand.vehicle_class === props.vcl.vehicle_class})
  const inClassCandsWithUpfits = filteredOptions.filter((cand) => { return cand.vehicle_class === props.vcl.vehicle_class && upfitsMatch(cand, props.vcl)})
  const inClassCandsWithoutUpfits = filteredOptions.filter((cand) => { return cand.vehicle_class === props.vcl.vehicle_class && !upfitsMatch(cand, props.vcl)})
  const notInClassCands = filteredOptions.filter((cand) => {return cand.vehicle_class !== props.vcl.vehicle_class})

  //This structure allows for options to be grouped even when options are filtered by searchbar.
  let recReplacement = {
    label: 'Recommended Replacement',
    options: []
  }
  let inClassGroupWithUpfits = {
    label: 'Candidates In Class with Special Vehicle Configuration',
    options: []
  }
  let inClassGroupWithoutUpfits = {
    label: 'Candidates In Class without Special Vehicle Configuration',
    options: []
  }
  let inClassGroup = {
    label: 'Candidates In Class',
    options: []
  }
  let notInClassGroup = {
    label: 'Candidates Not In Class',
    options: []
  }

  const returnObject = [];

  recReplacement.options.push({label: props.recommendation, value: -1})
  returnObject.push(recReplacement);

  if(props.vcl.upfits.length === 0) {
    // If upfits are not applicable for this vehicle, don't subdivide into upfit match vs not.
    inClassCands.sort((a,b) => {
      return a.overall < b.overall ? 1 : -1;
    }).forEach((c) => {
      inClassGroup.options.push({label: c.ymm, value: c.idx});
    })
    returnObject.push(inClassGroup);
  }
  else {
    // If the vehicle does have upfits, divide into in class w/ upfits and in class w/o upfits
    inClassCandsWithUpfits.sort((a,b) => {
      return a.overall < b.overall ? 1 : -1;
    }).forEach((c) => {
      inClassGroupWithUpfits.options.push({label: c.ymm, value: c.idx});
    })
    returnObject.push(inClassGroupWithUpfits);

    inClassCandsWithoutUpfits.sort((a,b) => {
      return a.overall < b.overall ? 1 : -1;
    }).forEach((c) => {
      inClassGroupWithoutUpfits.options.push({label: c.ymm, value: c.idx});
    })
    returnObject.push(inClassGroupWithoutUpfits);
  }
  notInClassCands.sort((a,b) => {
    return a.overall < b.overall ? 1 : -1;
  }).forEach((c) => {
    notInClassGroup.options.push({label: c.ymm, value: c.idx});
  })
  returnObject.push(notInClassGroup);
  return returnObject
}

const formatCandidateValue = (props) => {
  if (props.recommendation === 'No Candidates in Class' && props.value === -1) {
    return {label: props.recommendation, value: props.value}
  }
  else if (props.recommendation !== 'No Candidates in Class' && props.value === -1) {
    return {label: props.recommendation, value: props.value}
  }
  else {
    return {label: props.options[props.value].ymm, value: props.value}
  }
}

export function CandDropdownSelector(props) {
  // TEMPORARY logic to deal w/ rec that doesn't have equiv candidate - will refactor this
  //Save the original index for lookup within cands array
  const options = props.options.map((item, idx) => ({ ...item, idx })).sort((a, b) => (a.ymm < b.ymm ? -1 : 1))
  let formattedOptions = null;
  if (props.noCandidates) {
    formattedOptions = [{label: 'No Candidates', value: 'No Candidates', isDisabled: true}]
  }
  else {
    formattedOptions = formatCandidateOptions(options, props)
  }
  const selectedCand = formatCandidateValue(props)

  return (
    <SearchSelect
        onChange={props.handleSelectorChange}
        options={formattedOptions}
        value={selectedCand}
        width={'100%'}
        optionHeight={'40px'}
        menuShouldScrollIntoView={false}
    />
  );
}
