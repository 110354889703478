import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SawatchLogo from '../../images/sawatch-labs-logo-black.png'

export default function HeaderTable(props) {
  const [infoShown, setInfoShown] = useState(false)

  function showInfo() {
    setInfoShown(true);
  }

  function hideInfo() {
    setInfoShown(false);
  }


  return (
    <div id="ezev-header">
      <Link to="/ezev-fx">
        <img className="ezev-ic-ezev" src="https://images.sawatchlabs.com/ezev-logo.png" alt="ezEV Logo" />
      </Link>
      <a href="https://sawatchlabs.com/" target="_blank" rel="noopener noreferrer">
        <img 
          className="ezev-ic-sawatch"
          src={SawatchLogo}
          alt="Sawatch Labs Logo" />
      </a>
      <div className="ezev-heading-text table" >

        <h1 className="ezev-heading H1-ezEV" data-testid="dbDisplayName-testid">{props.dbDisplayName}</h1>
        <div className="ezev-table-heading-info-wrapper">
          <h1 className="ezev-heading H1-ezEV ezev-secondary-header" data-testid="groupName-testid">{props.groupName}</h1>
          {props.multipleGroups && <div className="ezev-info-container" data-testid="moreGroupsInfoBubble-testid">
            <img className="ezev-info-icon" src="https://images.sawatchlabs.com/info-icon.png" alt="Infomation Icon" onMouseOver={() => showInfo()} onMouseOut={() => hideInfo()} />
          </div>}

          {infoShown && <div className={`ezev-info-text`}>
            <p>To view vehicles in a different group, please return to the previous page and select from the dropdown.</p>
          </div>}
        </div>
      </div>
    </div>
  );

}
