import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { Roles } from "../core-components/utility-components/UserUtils";

export default function About({settings, user, activeLinkChange, kwRates}) {
  useEffect(() => {
    activeLinkChange('activeLink', 'about');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createRateDates(rateObj) {
    if (rateObj.startMonth == null || rateObj.stopMonth === null || rateObj.startMonth === undefined || rateObj.stopMonth === undefined)
        return "All Selected";
    if (rateObj.startDayOfMonth === null || rateObj.stopDayOfMonth === null || rateObj.startDayOfMonth === undefined || rateObj.stopDayOfMonth === undefined) {
        return `${rateObj.startMonth + " - " + rateObj.stopMonth}`;
    } else {
        return `${humanFriendlyMonth(rateObj.startMonth)} ${(rateObj.startDayOfMonth + 1)} - ${humanFriendlyMonth(rateObj.stopMonth)} ${(rateObj.stopDayOfMonth + 1)}`;
    }
}

function createRateDOW(rateObj) {
  if (rateObj.mondays === null) return "All Selected";
  let selectedDays = [];
  const dowArray = [
      "mondays",
      "tuesdays",
      "wednesdays",
      "thursdays",
      "fridays",
      "saturdays",
      "sundays",
  ];
  dowArray.forEach((d) => {
      if (rateObj[d] === true) {
          d = d.charAt(0).toUpperCase() + d.slice(1, 3);
          selectedDays.push(d);
      }
  });
  if (selectedDays.length > 0) {
      return selectedDays.join(', ');
  } else {
      return "All Selected";
  }
}

function createRateTOU(rateObj) {
  if (rateObj.startHour === null || rateObj.stopHour === null || rateObj.startHour === undefined || rateObj.stopHour === undefined ||
      rateObj.startMinute === null || rateObj.stopMinute === null || rateObj.startMinute === undefined || rateObj.stopMinute === undefined)
      return "All Selected";
  return `${rateObj.startTimestamp} - ${rateObj.stopTimestamp}`;
}

function humanFriendlyMonth(mnth) {
  switch (mnth) {
      case 0: return 'January';
      case 1: return 'February';
      case 2: return 'March';
      case 3: return 'April';
      case 4: return 'May';
      case 5: return 'June';
      case 6: return 'July';
      case 7: return 'August';
      case 8: return 'September';
      case 9: return 'October';
      case 10: return 'November';
      case 11: return 'December';
      default: return "-";
  }
}

  return (
    <div className="swt-ionev-about-wrapper">
      <div className="swt-ionev-about-section">
        <h2 className="swt-ionev-about-header">About</h2>
        <p className="swt-ionev-about-text">ionEV is designed to help fleet managers quickly understand how their EVs are operating and to identify opportunities to increase savings and GHG emissions reductions. The metrics are provided to support fleet reporting requirements and for tracking progress toward fleet sustainability goals.</p>
      </div>
      <div className="swt-ionev-assumptions-section">
        <h2 className="swt-ionev-about-header">Assumptions</h2>
        <div className="swt-ionev-assumptions-wrapper">
          <div className="swt-ionev-assumptions-list">
            <p className="swt-ionev-assumptions-label" data-testid="fuel-cost-assumption-label-testid">Fuel Cost ({user.userSettings.currency}): </p><p className="swt-ionev-assumptions-value" data-testid="fuel-cost-assumption-value-testid">{user.userSettings.currency_symbol}{settings['fuel_cost']} per {user.userSettings.liquid_volume_labels.longSingular.toLowerCase()}</p><br/>
            <p className="swt-ionev-assumptions-label">Vehicle Lifecycle (years): </p><p className="swt-ionev-assumptions-value">{settings['life_cycle']}</p><br/>
            <p className="swt-ionev-assumptions-label">State of Charge Threshold for Missed Opportunities (BEVs): </p><p className="swt-ionev-assumptions-value">{settings['max_soc_missed_opp']}%</p><br/>
            <p className="swt-ionev-assumptions-label">State of Charge Threshold for Missed Opportunities (PHEVs):</p><p className="swt-ionev-assumptions-value"> {settings['max_soc_missed_opp_phev']}%</p><br/>
            <br/>
            <p className="swt-ionev-about-text">Vehicle models and pricing are provided by the client. For more information, please contact us at <a href="mailto:info@sawatchlabs.com" className="ionev-link">info@sawatchlabs.com</a>.</p>
          </div>
        </div>
      </div>
      {user.role >= Roles.FleetAdmin &&
        <div className="swt-ionev-assumptions-edit" data-testid="assumptionsEdit-testid">To edit these assumptions,
          <Link
            className="ionev-link"
            to={{
              pathname: '/admin/settings',
              state: {redirectFrom: "/ionev/about"}
            }}
          >
            click here
          </Link>.
        </div>
      }
      <div className="swt-ionev-about-table-section">
        <h2 className="swt-ionev-about-header">Electricity Rates</h2>
        {!kwRates || kwRates.length === 0
        ? 
        <p className="swt-ionev-about-text">Your fleet's default kWh rate is {user.userSettings.currency_symbol}{settings.local_kwh_cost.toFixed(2)}. If you have a seasonal, time-of-use, or other electricity rate to include, please contact us at <a href="mailto:info@sawatchlabs.com" className="ionev-link">info@sawatchlabs.com</a>.</p>
        :
        <>
        <div className='swt-ionev-about-table-container'>
          <table className='swt-ionev-about-table'>
            <thead className='swt-ionev-about-table-thead'>
              <tr className='swt-ionev-about-table-tr'>
                <th className='swt-ionev-about-table-th'>Rate Name</th>
                <th className='swt-ionev-about-table-th'>Rate</th>
                <th className='swt-ionev-about-table-th'>Date Duration</th>
                <th className='swt-ionev-about-table-th'>Active Days</th>
                <th className='swt-ionev-about-table-th'>Active Times</th>
              </tr>
            </thead>
            <tbody className='swt-ionev-about-table-tbody'>
              {kwRates.map((rate, idx) => {
                return (
                  <tr key={idx} className='swt-ionev-about-table-tr'>
                    <td className='swt-ionev-about-table-td'>{rate.nickname}</td>
                    <td className='swt-ionev-about-table-td'>{user.userSettings.currency_symbol}{rate.kwhRate.toFixed(2)}</td>
                    <td className='swt-ionev-about-table-td'>{createRateDates(rate)}</td>
                    <td className='swt-ionev-about-table-td'>{createRateDOW(rate)}</td>
                    <td className='swt-ionev-about-table-td'>{createRateTOU(rate)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <p className="swt-ionev-about-text">If none of the above rates apply for a given time period, your fleet's default kWh rate of {user.userSettings.currency_symbol}{settings.local_kwh_cost.toFixed(2)} is used.</p>
      </>
      }
    </div>
    </div>
  );
}

// <p className="swt-ionev-assumptions-label">Values for 'Recommendation to Save'</p><br/>
// <p className="swt-ionev-assumptions-label">EV Yearly Miles Target: </p><p className="swt-ionev-assumptions-value">{settings['ev_yr_km_target']}</p><br/>
// <p className="swt-ionev-assumptions-label">Max % M.O as portion of events: </p><p className="swt-ionev-assumptions-value">{settings['max_pct_missed_opps_target']}%</p><br/>
