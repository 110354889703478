import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2';
import { formatTimeString } from '../../utils/ezio-utils/ConformUnits';

const hourQuarters = ["00", "15", "30", "45"];

const peakPointBackgroundColor = "rgba(3, 94, 91, .2)";
const offPeakPointBackgroundColor = "rgba(178, 177, 175,.8)";
const peakBackgroundColor = "rgba(3, 94, 91, .2)";
const offPeakBackgroundColor = "rgba(167, 166, 164, .04)";
const peakBorderColor = "rgba(3, 94, 91, .8)";
const offPeakBorderColor = "rgba(167, 166, 164, .5)";
const peakHoverBackgroundColor = "rgba(3, 94, 91, .7)";
const offPeakHoverBackgroundColor = "rgba(178, 177, 175, 1)";

type GraphDataset = {
  label: string,
  data: number[],
  pointRadius: number,
  pointHitRadius: number,
  backgroundColor: string,
  hoverBackgroundColor: string,
  borderColor: string,
  pointBackgroundColor: string,
  borderJoinStyle: "miter",
  fill: boolean,
  lineTension: number,
  borderWidth: number,
  order: number
}

type GraphDataSorted = {
  labels: string[],
  datasets: Array<GraphDataset>
}

function markPeaks(monthlyPeaks: any) {
  let max = -1;
  for(const d in monthlyPeaks){
    max = Math.max(max, monthlyPeaks[d].peakKw);
  }
  for(const d in monthlyPeaks){
   monthlyPeaks[d].isPeak = (monthlyPeaks[d].peakKw === max) ? true : false
  }
  //data.forEach((d) => { max = Math.max(max, d.maxKw) });
  //data.forEach((d) => { d.isPeak = (d.maxKw === max) ? true : false });
  //return data;
  return monthlyPeaks;
}

export function standardizeData(monthlyPeaks: any, userSettings: any) {
  if (!monthlyPeaks) return;
  monthlyPeaks = markPeaks(monthlyPeaks);
  let labels: string[] = [];
  
  for(const d in monthlyPeaks){
    if(labels.length > 0)continue;
    labels = monthlyPeaks[d].activity.map((d: [], idx: number) => {
      return formatTimeString(userSettings, Math.floor(idx / 4), hourQuarters[idx % 4]);
    });
  }
  let newSorted: Array<GraphDataset> = [];
  for(const d in monthlyPeaks){
    //data.forEach((d: any) => {
    const s: GraphDataset = {
      label: monthlyPeaks[d].longDate,
      data: monthlyPeaks[d].activity,
      pointRadius: 1,
      pointHitRadius: 3,
      pointBackgroundColor: (monthlyPeaks[d].isPeak) ? peakPointBackgroundColor : offPeakPointBackgroundColor,
      borderJoinStyle: "miter",
      fill: true,
      lineTension: 0.3,
      borderWidth: (monthlyPeaks[d].isPeak) ? 2.5 : 1,
      backgroundColor: (monthlyPeaks[d].isPeak) ? peakBackgroundColor : offPeakBackgroundColor,
      borderColor: (monthlyPeaks[d].isPeak) ? peakBorderColor : offPeakBorderColor,
      hoverBackgroundColor: (monthlyPeaks[d].isPeak) ? peakHoverBackgroundColor : offPeakHoverBackgroundColor,
      order: (monthlyPeaks[d].isPeak) ? -1 : 1
    };
    newSorted.push(s)
  }

  return({
    labels: labels,
    datasets: newSorted,
  })
}

export default function GraphMonthlyTod({ monthlyPeaks, userSettings, graphRef=null, setGraphLoaded}) {
  const [monthlyPeaksGraphData, setMonthlyPeaksGraphData] = useState<GraphDataSorted>(null);

  useEffect(() => {
    if (monthlyPeaks) {
      const data = standardizeData(monthlyPeaks, userSettings);
      setMonthlyPeaksGraphData(data);
    }
  }, [monthlyPeaks, userSettings]);

  const options = {
    // interaction: {
    //   mode: "dataset",
    //   intersect: true,
    // },
    transitions: {
      active: {
        animation: {
          duration: 0
        }
      }
    },
    animation: {
      onComplete: (animation) => {
        setGraphLoaded(true);
      }
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        // align: 'center',
        display: true,
        text: 'Daily kW Demand Peaks'
      },
      tooltip: {
        callbacks: {

          afterTitle: (context: any) => {
            return `${context[0].raw} kW`
          },
          label: (context: any) => {
            return `${context.dataset.label}`
          }
        }
      },

    },
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: "Peak kW"
        }
      },
      x: {
        display: true,
        title: {
          display: true,
          text: "Time of Day"
        },
        ticks: {
          maxTicksLimit: 24
        },
      },
    }

  };
  return (

    monthlyPeaksGraphData && options ?
      <Line
        id="monthly-tod-graph"
        width={600}
        height={400}
        data={monthlyPeaksGraphData}
        ref={graphRef}
        options={options}
      />
      : <></>

  )

}
