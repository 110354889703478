import React from 'react';
import { Link } from 'react-router-dom';
import SawatchLogo from '../../images/sawatch-labs-logo-black.png'

export default function HeaderSelection(props) {
  return(
    <div id="ezev-header">
      <Link to="/ezev-td">
        <img className="ezev-ic-ezev" src="https://images.sawatchlabs.com/ezev-logo.png" alt="ezEV Logo" />
      </Link>
      <a href="https://sawatchlabs.com/" target="_blank" rel="noopener noreferrer">
        <img 
          className="ezev-ic-sawatch"
          src={SawatchLogo}
          alt="Sawatch Labs Logo"/>
      </a>
      <div className="ezev-heading-text">
        <h1 className="ezev-heading H1-ezEV">{props.dbDisplayName}</h1>
        <h1 className="ezev-heading H1-ezEV ezev-secondary-header">{props.text}</h1>
      </div>
    </div>
  );
}
