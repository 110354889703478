import "../../../component-css/core-stylesheets/admin.css";
import AdminUsers from "./admin-components/AdminUsers";
import AdminSettings from "./admin-components/AdminSettings";
import VehicleManagementTable from "./admin-components/VehicleManagementTable";
import CandidateManagementTable from "./admin-components/CandidateManagementTable";
import UpfitsManagementTable from "./admin-components/UpfitsManagementTable";
import SeasonalRatesController from "./admin-components/AdminSeasonalRates";
import GroupManagementTable from "./admin-components/GroupManagementTable";
import SettingsChangeLog from "./admin-components/AdminSettingsChangeLog";
import { Route, Switch, Redirect, NavLink } from "react-router-dom";
import { NavigationTabs } from "@sawatchlabs/swt-components";
import * as S from '../../../styles/core-styles/AdminTools-styles';
import { Roles } from "../utility-components/UserUtils";

export default function AdminMain(props) {
  const { user, posthog } = props;
  const handleFetchErrors = (res) => {
    if (!res.ok) { throw Error(res.statusText); }
    return res;
  }

  const AdminRoute = ({ component: Component, posthog, db, dbDisplayName, dbSettings, product, user, apiURL, addDatabase, createNewUserPassword, passwordRules, handleFetchErrors, updateDbSettings, changeDbCobrandingLogo, hasDbCobrandingUrl, dbUiUpdating, dbSetupApproved, ...rest }) => {
      return (<Route exact {...rest}
        render={(props) =>
          <Component {...props}
            db={db}
            posthog={posthog}
            dbDisplayName={dbDisplayName}
            apiURL={apiURL}
            dbSettings={dbSettings}
            updateDbSettings={updateDbSettings}
            changeDbCobrandingLogo={changeDbCobrandingLogo}
            hasDbCobrandingUrl={hasDbCobrandingUrl}
            user={user}
            passwordRules={passwordRules}
            createNewUserPassword={createNewUserPassword}
            dbSetupApproved={dbSetupApproved}
            handleFetchErrors={handleFetchErrors}
            dbUiUpdating={dbUiUpdating} />} />)
  };

  return (
    <S.AdminContainer>
      <h2 className="swt-admin-console-ttl">{props.dbDisplayName} Admin Console</h2>
      { user.role >= Roles.SuperAdmin && // Super admin and above tabs
          <NavigationTabs>
            <NavLink data-testid={"settingsNav-testid"} to={`/admin/settings`}>Settings</NavLink>
            <NavLink data-testid={"usersNav-testid"} to={`/admin/users`}>Users</NavLink>
            <NavLink data-testid={"groupsNav-testid"} to={`/admin/groups`}>Groups</NavLink> 
            <NavLink data-testid={"ratesNav-testid"} to={`/admin/seasonalRates`}>Variable kWh Rates</NavLink>
            <NavLink data-testid={"vehiclesNav-testid"} to={`/admin/vehicles`}>Vehicles</NavLink> 
            <NavLink data-testid={"candidatesNav-testid"} to={`/admin/candidates`}>Candidates</NavLink> 
            <NavLink data-testid={"upfitsNav-testid"} to={`/admin/upfits`}>Upfits</NavLink>
            <NavLink data-testid={"changeLogNav-testid"} to={`/admin/changeLog`}>Change Log</NavLink>
          </NavigationTabs>
      }
      { user.role >= Roles.FleetAdmin && // Fleet/Partner admin tabs
        user.role < Roles.SuperAdmin && // Need upper role check to prevent double render
        <NavigationTabs>
          <NavLink data-testid={"settingsNav-testid"} to={`/admin/settings`}>Settings</NavLink>
          <NavLink data-testid={"usersNav-testid"} to={`/admin/users`}>Users</NavLink>
          <NavLink data-testid={"ratesNav-testid"} to={`/admin/seasonalRates`}>Variable kWh Rates</NavLink>
        </NavigationTabs>
      }

      { user.role >= Roles.SuperAdmin && // Super admin and above routes
          <S.AdminContentWrapper>
            <Switch>
              <AdminRoute exact path="/admin/seasonalRates" component={SeasonalRatesController} product={'seasonalRates'} user={props.user} db={props.db} dbDisplayName={props.dbDisplayName} apiURL={props.apiURL} dbSettings={props.dbSettings} />
              <AdminRoute exact path="/admin/settings" component={AdminSettings} posthog={posthog} product={'settings'} user={props.user} db={props.db} dbDisplayName={props.dbDisplayName} dbSettings={props.dbSettings} apiURL={props.apiURL} createNewUserPassword={props.createNewUserPassword} passwordRules={props.passwordRules} handleFetchErrors={handleFetchErrors} updateDbSettings={props.updateDbSettings} changeDbCobrandingLogo={props.changeDbCobrandingLogo} hasDbCobrandingUrl={props.hasDbCobrandingUrl} dbUiUpdating={props.dbUiUpdating} dbSetupApproved={props.dbSetupApproved} />
              <AdminRoute exact path="/admin/users" component={AdminUsers} product={'users'} user={props.user} db={props.db} dbDisplayName={props.dbDisplayName} apiURL={props.apiURL} createNewUserPassword={props.createNewUserPassword} passwordRules={props.passwordRules} handleFetchErrors={handleFetchErrors} />
              <AdminRoute exact path="/admin/groups" component={GroupManagementTable} product={'groups'} user={props.user} db={props.db} dbDisplayName={props.dbDisplayName} apiURL={props.apiURL} createNewUserPassword={props.createNewUserPassword} handleFetchErrors={handleFetchErrors} /> 
              <AdminRoute exact path="/admin/vehicles" component={VehicleManagementTable} product={'vehicles'} user={props.user} db={props.db} dbDisplayName={props.dbDisplayName} apiURL={props.apiURL} dbSettings={props.dbSettings} dbUiUpdating={props.dbUiUpdating}/> 
              <AdminRoute exact path="/admin/candidates" component={CandidateManagementTable} product={'candidates'} user={props.user} db={props.db} dbDisplayName={props.dbDisplayName} apiURL={props.apiURL} dbSettings={props.dbSettings} dbUiUpdating={props.dbUiUpdating}/> 
              <AdminRoute exact path="/admin/upfits" component={UpfitsManagementTable} product={'upfits'} user={props.user} db={props.db} dbDisplayName={props.dbDisplayName} apiURL={props.apiURL} dbSettings={props.dbSettings} dbUiUpdating={props.dbUiUpdating}/> 
              <AdminRoute exact path="/admin/changeLog" component={SettingsChangeLog} product={'changeLog'} user={props.user} db={props.db} dbDisplayName={props.dbDisplayName} apiURL={props.apiURL} dbSettings={props.dbSettings} /> 
              <Redirect exact push path="/admin" to="/admin/settings" />
            </Switch>
          </S.AdminContentWrapper>
      }
      { user.role >= Roles.FleetAdmin && // Fleet/Partner admin routes
        user.role < Roles.SuperAdmin && // Need upper role check to prevent double render
          <S.AdminContentWrapper>
            <Switch>
              <AdminRoute exact path="/admin/seasonalRates" component={SeasonalRatesController} product={'seasonalRates'} user={props.user} db={props.db} dbDisplayName={props.dbDisplayName} apiURL={props.apiURL} dbSettings={props.dbSettings} />
              <AdminRoute exact path="/admin/settings" component={AdminSettings} posthog={posthog} product={'settings'} user={props.user} db={props.db} dbDisplayName={props.dbDisplayName} dbSettings={props.dbSettings} apiURL={props.apiURL} createNewUserPassword={props.createNewUserPassword} passwordRules={props.passwordRules} handleFetchErrors={handleFetchErrors} updateDbSettings={props.updateDbSettings} dbUiUpdating={props.dbUiUpdating} />
              <AdminRoute exact path="/admin/users" component={AdminUsers} product={'users'} user={props.user} db={props.db} dbDisplayName={props.dbDisplayName} apiURL={props.apiURL} createNewUserPassword={props.createNewUserPassword} passwordRules={props.passwordRules} handleFetchErrors={handleFetchErrors} />
              <Redirect exact push path="/admin" to="/admin/settings" />
            </Switch>
          </S.AdminContentWrapper>
      }
    </S.AdminContainer>
  );
}
